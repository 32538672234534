import styled from 'styled-components'
import MuiTableSortLabel from '@mui/material/TableSortLabel'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'

export const TableHeadRow = styled.div`
    display: flex;
    padding-left: 12px;
    padding-right: 12px;
`

export const TableHeadCell = styled.div<{ width: number }>`
    font-size: 10px;
    color: ${props => props.theme.palette.black.main};
    font-weight: 700;
    text-align: left;
    padding: 10px 0;
    border-right: none;
    border-left: none;
    width: ${props => props.width}px;
    align-items: flex-start;
`

export const TableHead = styled.div`

`

export const TableLabel = styled.div`

`

export const TableRow = styled.div`
    display: flex;
    border-top: 1px solid ${props => props.theme.palette.primary.light};
    padding-left: 12px;
    padding-right: 12px;

    &:last-child {
        border-bottom: 1px solid ${props => props.theme.palette.primary.light};
    }
`

export const TableBody = styled.div`

`

export const TableCell = styled.div<{ width: number }>`
    font-size: 10px;
    padding: 0;
    border-right: none;
    border-left: none;
    width: ${props => props.width}px;
`

export const TableSortLabel = styled(MuiTableSortLabel).attrs(_props => ({
    IconComponent: ArrowDropDownIcon,
}))`
    display: flex;
    align-items: center;
    position: relative;

    && {
        color: ${props => props.theme.palette.black.main};
        font-weight: bold;
    }

    &&:hover svg, && svg {
        color: ${props => props.theme.palette.primary.main};
    }
`

export const GenomeSource = styled('span')`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    background-color: rgba(83, 203, 203, 0.5);
    margin-left: 5px;
`

export const ExomeSource = styled('span')`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    background-color: rgb(186, 173, 154, 0.5);
    margin-left: 5px;
`

export const SourceWrapper = styled.div`
    display: flex;
`
