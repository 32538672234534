import React from 'react'
import CheckBoxOutlineBlankIcon from '@/assets/icon/CheckBoxOutlineBlankIcon'
import { FormControlLabel } from '@mui/material'
import { PropsWithClassName } from '@/utils/styled'
import MuiCheckbox from '@mui/material/Checkbox'

type Props = {
    title: string | React.ReactNode
    checked: boolean
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export default function CheckboxLabel({
    title,
    checked,
    onChange,
    className,
}: PropsWithClassName<Props>) {
    return (
        <FormControlLabel
            className={className}
            control={
                <MuiCheckbox
                    checked={checked}
                    onChange={onChange}
                    icon={<CheckBoxOutlineBlankIcon />}
                />
            }
            label={title}
        />
    )
}
