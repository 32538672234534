import React from 'react'
import Link from '@/components/common/Link'
import MuiLink from '@mui/material/Link'
import { LinkProps } from 'react-router-dom'
import MoreIcon from '@mui/icons-material/More'
import styled, { css } from 'styled-components'

const linkStyles = css`
  display: flex;
  align-items: center;
  color: ${props => props.theme.palette.text.primary};
  text-decoration: unset;
  margin-right: 20px;

  &:hover {
    text-decoration: underline;
  }
`

const MoreIconFlipped = styled(MoreIcon)`
  transform: rotate(180deg);
  margin-left: 5px;
  color: ${props => props.theme.palette.detailLink.main};
  font-size: 14px;
`

const DetailLinkWrapper = styled(Link)`
  ${linkStyles}
`

const ExternalLinkWrapper = styled(MuiLink)`
  ${linkStyles}
`

export default function DetailLink({
    to,
    children,
    target,
    ...rest
}: LinkProps) {
    return target && typeof to === 'string' ? (
        <ExternalLinkWrapper href={to} {...rest}>
            {children}
            <MoreIconFlipped />
        </ExternalLinkWrapper>
    ) : (
        <DetailLinkWrapper to={to} {...rest}>
            {children}
            <MoreIconFlipped />
        </DetailLinkWrapper>
    )
}
