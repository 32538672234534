import React from 'react'

import { Track } from '@gnomad/region-viewer'

import PositionAxis from './PositionAxis'
import styled from 'styled-components'

const TrackWrapper = styled.div`
  text {
    transform: translate(0, 5px);
  }
`

export default function PositionAxisTrack() {
    return (
        <TrackWrapper>
            <Track>
                {({
                    scalePosition,
                    width,
                }: {
                    scalePosition: any
                    width: any
                }) => (
                    <PositionAxis scalePosition={scalePosition} width={width} />
                )}
            </Track>
        </TrackWrapper>
    )
}
