import React from 'react'
import { TableHeadCell } from '@/components/detail/DetailTable/TableComponents'
import { marks } from '@/components/detail/DetailTable/TableToolbar/TableFilters/AlleleFrequency/AlleleFrequency'
import styled from 'styled-components'

type Props = {
    containerWidth: number
}

const HeadCellBarItem = styled.div<{ left: number }>`
  display: flex;
  align-items: center;
  position: absolute;
  left: ${props => props.left}px;
  top: 0;
  bottom: 0;
  width: 50px;
  text-align: left;

  &:before {
    content: '';
    display: block;
    width: 1px;
    height: 2000px;
    background-color: ${props => props.theme.palette.primary['400']};
    position: absolute;
    top: 100%;
    left: -1px;
  }
`

export default function VariantPagePopulationFrequenciesHeadCellBar({
    containerWidth,
}: Props) {
    const countPositionForLabel = (value: number) => {
        const containerWidthCounted = containerWidth - 50
        return (
            containerWidthCounted -
            containerWidthCounted +
            (containerWidthCounted / 100) * value
        )
    }

    return (
        <TableHeadCell colSpan={8} sx={{ width: 575, position: 'relative' }}>
            {marks.map((mark, i) => {
                return (
                    <HeadCellBarItem
                        key={i}
                        left={countPositionForLabel(mark.value)}
                    >
                        {mark.label}
                    </HeadCellBarItem>
                )
            })}
        </TableHeadCell>
    )
}
