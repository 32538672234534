import React from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import styled from 'styled-components'

type Props = {
    title: string
    children: React.ReactNode
}

const CardHeader = styled.div`
  background-color: ${props => props.theme.palette.primary.main};
  color: ${props => props.theme.palette.white.main};
  text-align: center;
  padding: 16px 0 13px 0;
  border-radius: 5px;
`

const CardWrapper = styled(Card)`
  max-width: 410px;
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.16);
  text-align: center;
  
  p {
    text-align: center;
  }
`

export default function CardItem({ title, children }: Props) {
    return (
        <CardWrapper>
            <CardContent sx={{ padding: 0 }}>
                <CardHeader>
                    <Typography variant='h3' textTransform='uppercase'>
                        {title}
                    </Typography>
                </CardHeader>
                {children}
            </CardContent>
        </CardWrapper>
    )
}
