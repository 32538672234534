import React from 'react'
import MuiTable from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import MuiTableRow from '@mui/material/TableRow'
import MuiTableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import MuiTableContainer from '@mui/material/TableContainer'
import { VariantDetails } from '@/generated/graphql'
import styled from 'styled-components'
import dashIfUndefined from '@/components/pages/VariantPage/dashIfUndefined'

const TableContainer = styled(MuiTableContainer)`
  && .MuiTableCell-root, .MuiTypography-root {
    font-size: 16px;
  }
  padding-bottom: 1em;
`

const Table = styled(MuiTable)`
  & {
    border: unset;
  }
`

const TableRow = styled(MuiTableRow)`
  & {
    border: unset;
  }
`

const TableCell = styled(MuiTableCell)`
  text-align: right;

  & {
    border: unset;
    padding: 0;
  }
`

const TableCellBold = styled(TableCell)`
  text-align: left;

  & {
    font-weight: bold;
    color: ${props => props.theme.palette.black.main};
  }
`

const TableHeadCellBold = styled(TableCellBold)`
  text-align: right;
`

const TableCellExome = styled(TableHeadCellBold)`
  padding-left: 33px;
`

const TableCellGenome = styled(TableHeadCellBold)`
  padding-left: 33px;
`

type Props = {
    variant: VariantDetails
}

export default function VariantPageOccurrence({ variant }: Props) {
    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell />
                        <TableCellExome>Exomes:</TableCellExome>
                        <TableCellGenome>Genomes:</TableCellGenome>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCellBold>Allele Count:</TableCellBold>
                        <TableCell>
                            {dashIfUndefined(
                                variant.population_frequencies
                                    ?.find(freq => freq?.population_source === 'frequencies_exome_allele_stats')
                                    ?.populations
                                    ?.find(pop => pop?.population_subtype === 'all')
                                    ?.allele_stats
                                    ?.allele_count
                            )}
                        </TableCell>
                        <TableCell>
                            {dashIfUndefined(
                                variant.population_frequencies
                                    ?.find(freq => freq?.population_source === 'frequencies_genome_allele_stats')
                                    ?.populations
                                    ?.find(pop => pop?.population_subtype === 'all')
                                    ?.allele_stats
                                    ?.allele_count
                            )}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCellBold>Allele Number:</TableCellBold>
                        <TableCell>
                            {dashIfUndefined(
                                variant.population_frequencies
                                    ?.find(freq => freq?.population_source === 'frequencies_exome_allele_stats')
                                    ?.populations
                                    ?.find(pop => pop?.population_subtype === 'all')
                                    ?.allele_stats
                                    ?.allele_number
                            )}
                        </TableCell>
                        <TableCell>
                            {dashIfUndefined(
                                variant.population_frequencies
                                    ?.find(freq => freq?.population_source === 'frequencies_genome_allele_stats')
                                    ?.populations
                                    ?.find(pop => pop?.population_subtype === 'all')
                                    ?.allele_stats
                                    ?.allele_number
                            )}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCellBold>Allele Frequency:</TableCellBold>
                        <TableCell>
                            {dashIfUndefined((() => {
                                const matchingPopulationStats = variant.population_frequencies
                                    ?.find(freq => freq?.population_source === 'frequencies_exome_allele_stats')
                                    ?.populations
                                    ?.find(pop => pop?.population_subtype === 'all')
                                    ?.allele_stats
                                return matchingPopulationStats?.allele_frequency ? (
                                    (matchingPopulationStats.allele_frequency).toPrecision(5)
                                ) : undefined
                            })())}
                        </TableCell>
                        <TableCell>
                            {dashIfUndefined((() => {
                                const matchingPopulationStats = variant.population_frequencies
                                    ?.find(freq => freq?.population_source === 'frequencies_genome_allele_stats')
                                    ?.populations
                                    ?.find(pop => pop?.population_subtype === 'all')
                                    ?.allele_stats
                                return matchingPopulationStats?.allele_frequency ? (
                                    (matchingPopulationStats.allele_frequency).toPrecision(5)
                                ) : undefined
                            })())}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCellBold>Allele Frequency XX:</TableCellBold>
                        <TableCell>
                            {dashIfUndefined((() => {
                                const matchingPopulationStats = variant.population_frequencies
                                    ?.find(freq => freq?.population_source === 'frequencies_exome_allele_stats')
                                    ?.populations
                                    ?.find(pop => pop?.population_subtype === 'female')
                                    ?.allele_stats
                                return matchingPopulationStats?.allele_frequency ? (
                                    (matchingPopulationStats.allele_frequency).toPrecision(5)
                                ) : undefined
                            })())}
                        </TableCell>
                        <TableCell>
                            {dashIfUndefined((() => {
                                const matchingPopulationStats = variant.population_frequencies
                                    ?.find(freq => freq?.population_source === 'frequencies_genome_allele_stats')
                                    ?.populations
                                    ?.find(pop => pop?.population_subtype === 'female')
                                    ?.allele_stats
                                return matchingPopulationStats?.allele_frequency ? (
                                    (matchingPopulationStats.allele_frequency).toPrecision(5)
                                ) : undefined
                            })())}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCellBold>Allele Frequency XY:</TableCellBold>
                        <TableCell>
                            {dashIfUndefined((() => {
                                const matchingPopulationStats = variant.population_frequencies
                                    ?.find(freq => freq?.population_source === 'frequencies_exome_allele_stats')
                                    ?.populations
                                    ?.find(pop => pop?.population_subtype === 'male')
                                    ?.allele_stats
                                return matchingPopulationStats?.allele_frequency ? (
                                    (matchingPopulationStats.allele_frequency).toPrecision(5)
                                ) : undefined
                            })())}
                        </TableCell>
                        <TableCell>
                            {dashIfUndefined((() => {
                                const matchingPopulationStats = variant.population_frequencies
                                    ?.find(freq => freq?.population_source === 'frequencies_genome_allele_stats')
                                    ?.populations
                                    ?.find(pop => pop?.population_subtype === 'male')
                                    ?.allele_stats
                                return matchingPopulationStats?.allele_frequency ? (
                                    (matchingPopulationStats.allele_frequency).toPrecision(5)
                                ) : undefined
                            })())}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCellBold>Coverage (Mean):</TableCellBold>
                        <TableCell>
                            {dashIfUndefined(
                                variant.coverage_exome?.mean?.toPrecision(5)
                            )}
                        </TableCell>
                        <TableCell>
                            {dashIfUndefined(
                                variant.coverage_genome?.mean?.toPrecision(5)
                            )}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCellBold>Homozygote Count</TableCellBold>
                        <TableCell>
                            {dashIfUndefined(
                                variant.population_frequencies
                                    ?.find(freq => freq?.population_source === 'frequencies_exome_allele_stats')
                                    ?.populations
                                    ?.find(pop => pop?.population_subtype === 'all')
                                    ?.allele_stats?.homozygote_count
                            )}
                        </TableCell>
                        <TableCell>
                            {dashIfUndefined(
                                variant.population_frequencies
                                    ?.find(freq => freq?.population_source === 'frequencies_genome_allele_stats')
                                    ?.populations
                                    ?.find(pop => pop?.population_subtype === 'all')
                                    ?.allele_stats?.homozygote_count
                            )}
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    )
}
