import React, { useEffect } from 'react'
import TableFilterRange from '@/components/detail/DetailTable/TableToolbar/TableFilters/TableFilterRange'
import { FilterProps } from 'react-table'
import { unscale } from '@/components/detail/DetailTable/TableToolbar/TableFilters/utils'

export const marks = [
    {
        value: 0,
        scaledValue: 0.0001,
        label: '0.0001',
    },
    {
        value: 10,
        scaledValue: 0.001,
        label: '0.001',
    },
    {
        value: 20,
        scaledValue: 0.01,
        label: '0.01',
    },
    {
        value: 30,
        scaledValue: 0.1,
        label: '0.1',
    },
    {
        value: 40,
        scaledValue: 0.3,
        label: '0.3',
    },
    {
        value: 50,
        scaledValue: 0.5,
        label: '0.5',
    },
    {
        value: 70,
        scaledValue: 0.7,
        label: '0.7',
    },
    {
        value: 100,
        scaledValue: 1,
        label: '1',
    },
]

export default function AlleleFrequency({
    column: { setFilter, filterValue },
}: FilterProps<any>) {
    const initialValue = [0, 100]
    const [value, setValue] = React.useState<number | number[]>(initialValue)

    useEffect(() => {
        if (filterValue === undefined) {
            setValue([0, 100])
        }
    }, [filterValue])

    const filterValueOnClose = React.useMemo(() => {
        if (!filterValue) {
            return filterValue
        } else {
            return [
                unscale(filterValue[0], marks),
                unscale(filterValue[1], marks),
            ]
        }
    }, [filterValue])

    return (
        <TableFilterRange
            id='allele-filter'
            title='Allele Frequency'
            value={value}
            setValue={setValue}
            rangeArray={[1, 100]}
            marks={marks}
            setFilter={setFilter}
            step={0.0001}
            onClose={() =>
                setValue(filterValue ? filterValueOnClose : initialValue)
            }
        ></TableFilterRange>
    )
}
