import { Gene } from '@/generated/graphql'
import Box from '@mui/material/Box'
import Link from '@/components/common/Link'
import React, { useMemo, useState } from 'react'
import { ButtonBase } from '@mui/material'
import styled from 'styled-components'

const GenePageTranscriptsShowMoreButton = styled(ButtonBase)`
  white-space: nowrap;
  margin-left: 20px;
`

const GenePageTranscriptsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`

type Props = {
    transcripts: Gene['transcript_ids']
}

export default function GenePageTranscripts({ transcripts }: Props) {
    const [showMore, setShowMore] = useState<boolean>(
        (transcripts ? transcripts.length : 0) < 10
    )

    const displayedTranscripts = useMemo<Gene['transcript_ids']>(() => {
        if (showMore) {
            return transcripts
        } else {
            return transcripts?.slice(0, 10)
        }
    }, [showMore, transcripts])

    return (
        <>
            <GenePageTranscriptsWrapper>
                {displayedTranscripts?.map(
                    transcript =>
                        transcript && (
                            <Box
                                key={transcript}
                                sx={{ marginLeft: '10px' }}
                            >
                                <Link
                                    to={`/transcript/${transcript}`}
                                >
                                    {transcript}
                                </Link>
                            </Box>
                        )
                )}
            </GenePageTranscriptsWrapper>
            {!showMore && (
                <GenePageTranscriptsShowMoreButton
                    onClick={() => setShowMore(true)}
                >
                    Show more
                </GenePageTranscriptsShowMoreButton>
            )}
        </>
    )
}
