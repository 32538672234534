import React from 'react'
import styled from 'styled-components'
import TableFilters from '@/components/detail/DetailTable/TableToolbar/TableFilters/TableFilters'
import TableHideColumnDrawer from '@/components/detail/DetailTable/TableToolbar/TableHideColumnDrawer/TableHideColumnDrawer'
import TableColumnExport from '@/components/detail/DetailTable/TableToolbar/TableColumnExport/TableColumnExport'
import { PageType } from '@/store/reducers'
import { TableInstance } from 'react-table'

const TableToolbarWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .MuiButtonBase-root {
    font-size: 10px;
  }
`

const TableToolbarActionsWrapper = styled.div`
  display: flex;
  align-items: center;
`

type Props = {
    pageType: PageType
    stateColumns: Array<any>
    activeColumns: Array<any>
    data: any
    visibleData: any[]
    instance: TableInstance
}

export default function TableToolbar({
    pageType,
    activeColumns,
    stateColumns,
    data,
    visibleData,
    instance,
}: Props) {
    return (
        <TableToolbarWrapper>
            <TableFilters instance={instance} />
            <TableToolbarActionsWrapper>
                <TableHideColumnDrawer
                    pageType={pageType}
                    columns={stateColumns}
                />
                <TableColumnExport
                    data={data}
                    visibleData={visibleData}
                    columns={activeColumns}
                    pageType={pageType}
                />
            </TableToolbarActionsWrapper>
        </TableToolbarWrapper>
    )
}
