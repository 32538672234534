import React from 'react'
import { gql } from '@apollo/client'
import { BaseQueryChildrenType } from '@/components/types/BaseQueryChildrenType'
import BaseQuery from '@/components/BaseQuery'
import {
    TableCell as CoreTableCell,
    Table,
    TableHeadCell as CoreTableHeadCell,
    TableBody,
    TableRow,
    TableHead,
} from '@/components/detail/DetailTable/TableComponents'
import styled from 'styled-components'
import ProjectNewsBox from '@/components/pages/HomePage/ProjectNewsBox'

const query = gql`
    query {
        dataset {
            dataset_id
            description
            reference_genome
            version
            gene_count
            transcript_count
            variant_count
        }
    }
`

const TableHeadCell = styled(CoreTableHeadCell)`
`

const TableCell = styled(CoreTableCell)`
`

const InfoPageWrapper = styled.div`
  padding: 15px;
`

export default function InfoPage() {
    return (
        <BaseQuery
            query={query}
            loadingMessage='Loading dataset info'
            notFoundMessage='Dataset info is not available'
        >
            {({ data }: BaseQueryChildrenType) => {
                return (
                    <>
                        <InfoPageWrapper>
                            <Table>
                                <TableHead>
                                    <TableHeadCell>Dataset id:</TableHeadCell>
                                    <TableHeadCell>Description:</TableHeadCell>
                                    <TableHeadCell>
                                        Reference genome:
                                    </TableHeadCell>
                                    <TableHeadCell>Gene count:</TableHeadCell>
                                    <TableHeadCell>
                                        Variant count:
                                    </TableHeadCell>
                                    <TableHeadCell>
                                        Transcript count:
                                    </TableHeadCell>
                                    <TableHeadCell>Version:</TableHeadCell>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>
                                            {data.dataset.dataset_id}
                                        </TableCell>
                                        <TableCell>
                                            Enigma database v1.1
                                        </TableCell>
                                        <TableCell>
                                            {data.dataset.reference_genome}
                                        </TableCell>
                                        <TableCell>
                                            {data.dataset.gene_count}
                                        </TableCell>
                                        <TableCell>
                                            {data.dataset.variant_count}
                                        </TableCell>
                                        <TableCell>
                                            {data.dataset.transcript_count}
                                        </TableCell>
                                        <TableCell>
                                            1.1
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </InfoPageWrapper>
                        <ProjectNewsBox />
                    </>
                )
            }}
        </BaseQuery>
    )
}
