import React, { PropsWithChildren } from 'react'
import { TableCell } from '@/components/detail/DetailTable/TableComponents'
import styled from 'styled-components'

type Props = {
    size: number
}

const TableBarCell = styled(TableCell)`
  && {
    border-left: 1px solid ${props => props.theme.palette.primary['400']};
  }
`

const TableBarCellRectangle = styled.div<{ width: number }>`
  width: ${props => props.width}px;
  height: 13px;
  background-color: ${props => props.theme.palette.primary.main};
`

const VariantPagePopulationFrequenciesCellBar = React.forwardRef<
    HTMLTableCellElement,
    PropsWithChildren<Props>
>(({ size, children }, ref) => {
    return (
        <TableBarCell colSpan={8} ref={ref}>
            {children}
            <TableBarCellRectangle width={size} />
        </TableBarCell>
    )
})

VariantPagePopulationFrequenciesCellBar.displayName =
    'VariantPagePopulationFrequenciesCellBar'

export default VariantPagePopulationFrequenciesCellBar
