import * as React from 'react'

export default function Search() {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width={20} height={20}>
            <circle
                data-name='Ellipse 2'
                cx={10}
                cy={10}
                r={10}
                fill='currentColor'
            />
            <g>
                <g>
                    <path fill='none' d='M3.243 3.243h13.514v13.514H3.243z' />
                </g>
                <g>
                    <path
                        d='M12.005 11.231a4.121 4.121 0 0 0 .838-3.04 4.244 4.244 0 0 0-3.856-3.68 4.19 4.19 0 1 0-.838 8.338 4.282 4.282 0 0 0 3.074-.809l3.493 3.458a.532.532 0 0 0 .782 0 .529.529 0 0 0 0-.781Zm-2.822.5a3.071 3.071 0 0 1-3.521-3.565 3.015 3.015 0 0 1 2.487-2.484 3.043 3.043 0 0 1 3.546 3.542 3.1 3.1 0 0 1-2.515 2.51Z'
                        fill='currentColor'
                    />
                </g>
            </g>
        </svg>
    )
}
