import React from 'react'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from 'styled-components'
import createTheme from './assets/styles/createTheme'
import Routes from './Routes'
import configureStore from './store/store'
import { Provider } from 'react-redux'
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client'

const { store } = configureStore()

const client = new ApolloClient({
    //uri: 'https://ejebhemaka.execute-api.eu-central-1.amazonaws.com/', // http://localhost:45502/
    //uri: 'http://localhost:8000/', // http://localhost:45502/
    uri: 'https://czechgenome.cz:45502/', // http://localhost:45502/
    cache: new InMemoryCache(),
})

function App() {
    const theme = createTheme()

    return (
        <ApolloProvider client={client}>
            <Provider store={store}>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <Routes />
                </ThemeProvider>
            </Provider>
        </ApolloProvider>
    )
}

export default App
