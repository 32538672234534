import React, { useRef } from 'react'
import styled from 'styled-components'

import { Searchbox as GnomadSearchbox } from '@gnomad/ui'

import { fetchSearchResults } from './search'
import { useLocation, useNavigate } from 'react-router-dom'
import Search from '../../../assets/icon/Search'
import { ADD_RECENT_SEARCH_ITEM } from '@/store/types'
import { useDispatch, useSelector } from 'react-redux'
import { useApolloClient } from '@apollo/client'
import { InitialStateType } from '@/store/reducers'
import Alert from '@mui/material/Alert'
import { ThemeColorsHospitalic } from '@/assets/styles/color/ThemeColorsHospitalic'

const SearchBoxWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 40px;

  input {
    padding: 6px 13px;
    border-radius: 12.5px;
    border: 1px solid ${props => props.theme.palette.primary.main};
  }

  @media (max-width: 1160px) {
    display: none;
  }
`

const SearchBoxInner = styled.div`
  display: flex;
  align-items: center;
  position: relative;
    color: ${ThemeColorsHospitalic.TEXT_PRIMARY};
`

const SearchIconWrapper = styled.span`
  display: inline-flex;
  margin-left: 10px;
  cursor: pointer;
  color: ${props => props.theme.palette.primary.light};
    
    path {
        color: ${props => props.theme.palette.primary.main};
    }
`

const SearchBoxError = styled.div`
  font-size: 12px;
  position: absolute;
  right: 100%;
  white-space: nowrap;
`

type Props = {
    placeholder?: string
}

export default function SearchBox({
    placeholder = 'Search by gene, region, transcript or variant',
    ...rest
}: Props) {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const client = useApolloClient()
    const { pathname } = useLocation()

    // Update search dataset when active dataset changes.
    // Cannot rely on props for this because the top bar does not re-render.

    const innerSearchbox = useRef(null)

    const error = useSelector((state: InitialStateType) => state.search.error)

    return (
        <SearchBoxWrapper>
            <SearchBoxInner>
                {error && (
                    <SearchBoxError>
                        <Alert severity='error'>
                            Entered range is too big!
                        </Alert>
                    </SearchBoxError>
                )}
                <GnomadSearchbox
                    // Clear input when URL changes
                    key={pathname}
                    {...rest}
                    ref={innerSearchbox}
                    fetchSearchResults={query =>
                        fetchSearchResults(query, client, dispatch)
                    }
                    placeholder={placeholder}
                    width={415}
                    onSelect={(url: any, item) => {
                        navigate(url)
                        dispatch(
                            ADD_RECENT_SEARCH_ITEM({
                                label: item.label,
                                url: item.value,
                            })
                        )
                    }}
                />
                <SearchIconWrapper>
                    <Search />
                </SearchIconWrapper>
            </SearchBoxInner>
        </SearchBoxWrapper>
    )
}
