import React, { useMemo } from 'react'
import DetailTable from '@/components/detail/DetailTable/DetailTable'
import { Gene } from '@/generated/graphql'
import { columns } from '@/store/storage/gene'

type Props = {
    gene: Gene
    setData: (data: any) => void
    setVisibleColumns: (value: Array<number>) => void
}

export default function GenePageTable({
    gene,
    setData,
    setVisibleColumns,
}: Props) {
    const data = useMemo(() => {
        if (gene.variants?.length) {
            return gene.variants.map(variant => ({
                ...variant,
                gene: gene.gene_symbol,
            }))
        }
        return []
    }, [])

    return (
        <DetailTable
            columns={columns}
            data={data}
            setData={setData}
            pageType='gene'
            setVisibleColumns={setVisibleColumns}
        />
    )
}
