import styled from 'styled-components'
import MuiTableRow from '@mui/material/TableRow'
import MuiTableCell from '@mui/material/TableCell'
import MuiTableBody from '@mui/material/TableBody'
import MuiTableHead from '@mui/material/TableHead'
import MuiTableSortLabel from '@mui/material/TableSortLabel'
import MuiTable from '@mui/material/Table'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'

export const Table = styled(MuiTable)`
  && {
    font-size: 10px;
  }
`

export const TableHeadCell = styled(MuiTableCell)`
  && {
    font-size: 10px;
    color: ${props => props.theme.palette.black.main};
    font-weight: 700;
    padding: 10px 0;
    border-right: none;
    border-left: none;
  }
`

export const TablePopulationCellSubHeader = styled(MuiTableCell)`
  && {
    font-size: 10px;
    color: ${props => props.theme.palette.black.main};
    font-weight: 700;
    padding: 5px 0 5px 5px;
    border-right: none;
    border-left: none;
  }
`

export const TableLabel = styled.div`

`

export const TableRow = styled(MuiTableRow)`

`

export const TableBody = styled(MuiTableBody)`

`

export const TableHead = styled(MuiTableHead)`

`

export const TableCell = styled(MuiTableCell)`
  && {
    font-size: 10px;
    padding: 0;
    border-right: none;
    border-left: none;
  }
`

export const TableSortLabel = styled(MuiTableSortLabel).attrs(_props => ({
    IconComponent: ArrowDropDownIcon,
}))`
  display: flex;
  flex-direction: row-reverse;

  && {
    color: ${props => props.theme.palette.black.main};
    font-weight: bold;
  }

  &&:hover svg, && svg {
    color: ${props => props.theme.palette.primary.main};
  }

`
