import React from 'react'
import { Region } from '@/generated/graphql'
import DetailTrack from '@/components/detail/DetailTrack/DetailTrack'

type Props = {
    region: Region
    visibleVariant: Array<number>
    tableData: any
}

export default function RegionPageTrack({
    region,
    visibleVariant,
    tableData,
}: Props) {
    const data = region.variants

    return (
        <DetailTrack
            variants={data}
            start={region.start}
            stop={region.stop}
            genomeCoverage={region.coverage_genome}
            exomeCoverage={region.coverage_exome}
            visibleVariant={visibleVariant}
            variantsInTable={tableData}
        />
    )
}
