export default function formatAlleleFrequency(value: number): string {
    let s
    if (value === null || value === undefined) {
        s = ''
    } else {
        const truncated = Number(value.toPrecision(3))
        if (truncated === 0 || truncated === 1) {
            s = value.toFixed(0)
        } else {
            s = truncated.toExponential(2)
        }
    }
    return s
}
