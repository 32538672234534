import React from 'react'
import styled from 'styled-components'

const DetailSubheadingWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`

type Props = {
    children: React.ReactNode
}

export default function DetailSubheading({ children }: Props) {
    return <DetailSubheadingWrapper>{children}</DetailSubheadingWrapper>
}
