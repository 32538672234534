import React from 'react'
import styled from 'styled-components'
import CoreCheckboxLabel from '@/components/common/CheckboxLabel'

const TableFilterCheckboxWrapper = styled.div`
  display: flex;
  align-items: center;

  & .MuiTypography-root {
    font-size: 10px;
    text-transform: uppercase;
  }
`

const CheckboxLabel = styled(CoreCheckboxLabel)`
  && {
    margin-left: 0;
  }

  && .MuiCheckbox-root {
    padding: 0 3px 0 0;

    svg {
      color: ${props => props.theme.palette.white.main};
    }
  }

  && .Mui-checked svg {
    width: 14px;
    height: 14px;
  }
`

type Props = {
    title: string
    checked: boolean
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export default function TableFilterCheckbox({
    title,
    checked,
    onChange,
}: Props) {
    return (
        <TableFilterCheckboxWrapper>
            <CheckboxLabel
                title={title}
                checked={checked}
                onChange={onChange}
            />
        </TableFilterCheckboxWrapper>
    )
}
