import React from 'react'
import BaseQuery from '../../BaseQuery'
import { BaseQueryChildrenType } from '../../types/BaseQueryChildrenType'
import { useParams } from 'react-router-dom'
import { gql } from '@apollo/client'
import GenePage from './GenePage'

const query = gql`
    query Gene($geneId: String, $geneSymbol: String) {
        gene(gene_id: $geneId, gene_symbol: $geneSymbol) {
            gene_id
            gene_symbol
            name
            canonical_transcript_id
            chrom
            start
            stop
            transcript_ids
            variants {
                chrom
                ref
                alt
                length
                transcript_id_canonical
                source
                transcript_consequence_major {
                    gene_symbol
                    gene_id
                    consequence
                    transcript_id
                }
                source
                pos
                variant_id
                czechgenome_genome_frequencies_allAf
                czechgenome_genome_frequencies_allAc
                czechgenome_genome_frequencies_allAn
                czechgenome_genome_frequencies_allHc
                czechgenome_genome_frequencies_maleAf
                czechgenome_genome_frequencies_maleAc
                czechgenome_genome_frequencies_maleAn
                czechgenome_genome_frequencies_maleHc
                czechgenome_genome_frequencies_femaleAf
                czechgenome_genome_frequencies_femaleAc
                czechgenome_genome_frequencies_femaleAn
                czechgenome_genome_frequencies_femaleHc
                clinical_significance
                rsids
            }
        }
    }
`

type Params = {
    gene: string
}

export default function GenePageContainer() {
    const { gene = '' } = useParams<Params>()

    const variables = {
        [gene.startsWith('ENSG') ? 'geneId' : 'geneSymbol']: gene,
    }

    return (
        <BaseQuery
            query={query}
            variables={variables}
            loadingMessage='Loading gene'
            notFoundMessage='Gene not found'
        >
            {({ data }: BaseQueryChildrenType) => <GenePage gene={data.gene} />}
        </BaseQuery>
    )
}
