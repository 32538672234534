import React from 'react'
import styled from 'styled-components'

import { RegionViewer as GnomadRegionViewer } from '@gnomad/region-viewer'

const RegionViewerWrapper = styled.div`
  font-size: 12px;
  
  .vx-axis-left {
    .vx-axis-tick:first-child text {
      transform: translate(0, -6px);
    }
    
    line {
      display: none;
    }
  }
  
  
`

export default function RegionViewer(props: any) {
    return (
        <RegionViewerWrapper>
            <GnomadRegionViewer {...props} />
        </RegionViewerWrapper>
    )
}
