import * as React from 'react'
import { Draggable } from 'react-beautiful-dnd'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Checkbox from '@mui/material/Checkbox'
import { useDispatch } from 'react-redux'
import { TOGGLE_ITEM_ACTIVE } from '@/store/types'
import { PageType } from '@/store/reducers'
import styled from 'styled-components'

const TableHideColumnDrawerItemCheckBox = styled(Checkbox)`
  padding-top: 0;
  padding-bottom: 0;
`

const TableHideColumnDrawerItemListItemText = styled(ListItemText)`
  & .MuiTypography-root {
    font-size: 12px;
      font-weight: 700;
  }
`

export type DraggableListItemProps = {
    item: any
    index: number
    pageType: PageType
}

const TableHideColumnDrawerItem = ({
    item,
    index,
    pageType,
}: DraggableListItemProps) => {
    const dispatch = useDispatch()

    const onCheckBoxChange = () => {
        dispatch(TOGGLE_ITEM_ACTIVE(item.id, pageType))
    }

    return (
        <Draggable draggableId={String(item.id)} index={index}>
            {provided => (
                <ListItem
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                >
                    <>
                        <TableHideColumnDrawerItemCheckBox
                            checked={item.active}
                            onChange={onCheckBoxChange}
                        />
                        <TableHideColumnDrawerItemListItemText
                            primary={item.Header}
                        />
                    </>
                </ListItem>
            )}
        </Draggable>
    )
}

export default TableHideColumnDrawerItem
