import React from 'react'
import { Link as RouterLink, LinkProps } from 'react-router-dom'
import MuiLink from '@mui/material/Link'

const LinkBehavior = React.forwardRef<
    any,
    Omit<LinkProps, 'to'> & { href: LinkProps['to'] }
>((props, ref) => {
    const { href, ...other } = props
    // Map href (Material-UI) -> to (react-router)
    return <RouterLink ref={ref} to={href} {...other} />
})

LinkBehavior.displayName = 'LinkBehavior'

export default function Link(props: LinkProps) {
    return <MuiLink component={LinkBehavior} {...props} />
}
