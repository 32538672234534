import * as React from 'react'

type Props = {
    fill?: string
}

export default function Cross({ fill = '#53cbcb', ...rest }: Props) {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width={20}
            height={20}
            {...rest}
        >
            <path
                d='M20 2.014 17.986 0 10 7.986 2.014 0 0 2.014 7.986 10 0 17.986 2.014 20 10 12.014 17.986 20 20 17.986 12.014 10Z'
                fill={fill}
            />
        </svg>
    )
}
