import * as React from 'react'

export default function Location() {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width={13.846} height={22.153}>
            <path
                d='M6.923 0A6.923 6.923 0 0 0 0 6.923c0 6.923 6.923 15.23 6.923 15.23s6.922-8.307 6.922-15.23A6.923 6.923 0 0 0 6.922 0Zm0 11.163a4.24 4.24 0 1 1 4.24-4.24 4.24 4.24 0 0 1-4.24 4.24Zm-2.678-4.24a2.683 2.683 0 1 1 2.683 2.683 2.683 2.683 0 0 1-2.683-2.683Z'
                fill='currentColor'
            />
        </svg>
    )
}
