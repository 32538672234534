import React, { useMemo } from 'react'
import DetailTable from '@/components/detail/DetailTable/DetailTable'
import { Region } from '@/generated/graphql'
import { columns } from '@/store/storage/region'

type Props = {
    region: Region
    setVisibleColumns: (value: Array<number>) => void
    setData: (data: any) => void
}

export default function RegionPageTable({
    region,
    setVisibleColumns,
    setData,
}: Props) {
    const data = useMemo(() => {
        if (region.variants?.length) {
            return region.variants.map(variant => ({
                ...variant,
            }))
        }
        return []
    }, [])

    return (
        <DetailTable
            columns={columns}
            data={data ? data : []}
            pageType='gene'
            setVisibleColumns={setVisibleColumns}
            setData={setData}
        />
    )
}
