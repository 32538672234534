import React from 'react'
import VEPFilter from '@/components/detail/DetailTable/TableToolbar/TableFilters/VEPFilter/VEPFilter'
import { CellProps } from 'react-table'
import AlleleFrequency from '@/components/detail/DetailTable/TableToolbar/TableFilters/AlleleFrequency/AlleleFrequency'
import SourceFilter from '@/components/detail/DetailTable/TableToolbar/TableFilters/SourceFilter/Source'
//import formatAlleleFrequency from '@/utils/formatAlleleFrequency'
import RegionFilter from '@/components/detail/DetailTable/TableToolbar/TableFilters/RegionFilter/RegionFilter'
import {
    ExomeSource,
    GenomeSource,
    SourceWrapper,
} from '@/components/detail/DetailTable/DetailTableComponents'
import ShortenedCell from '@/components/detail/DetailTable/ShortenedCell'
import Link from '@/components/common/Link'
import MuiLink from '@mui/material/Link'
import { useLocation } from 'react-router-dom'

export const columns = [
    {
        id: '0',
        Header: 'Variant id',
        accessor: 'variant_id',
        minWidth: 220,
        grow: 1,
        Cell: ({ value }: CellProps<any>) => {
            const location = useLocation()

            return (
                <Link
                    to={`/variant/${value}?back=${encodeURIComponent(
                        location.pathname
                    )}`}
                >
                    <ShortenedCell length={20} content={value} />
                </Link>
            )
        },
    },
    {
        id: '1',
        Header: 'Source',
        accessor: 'source',
        Cell: ({ value }: CellProps<any>) => {
            return (
                <SourceWrapper>
                    {value.map((source: string) => {
                        if (source === 'genome') {
                            return <GenomeSource key={source}>G</GenomeSource>
                        }
                        return <ExomeSource key={source}>E</ExomeSource>
                    })}
                </SourceWrapper>
            )
        },
        filter: 'multiSelect',
        Filter: SourceFilter,
        minWidth: 80,
        grow: 1,
    },
    {
        id: '2',
        Header: 'Chrom',
        accessor: 'chrom',
        minWidth: 50,
        grow: 1,
    },
    {
        id: '3',
        Header: 'Start',
        accessor: 'pos',
        minWidth: 70,
        grow: 1,
    },
    {
        id: '4',
        Header: 'End',
        accessor: (row: any) => row.pos + (row.length ? row.length : 0),
        minWidth: 70,
        grow: 1,
    },
    {
        id: '5',
        Header: 'Ref',
        accessor: 'ref',
        minWidth: 80,
        Cell: ({ row }: CellProps<any>) => {
            return <ShortenedCell length={10} content={row.original['ref']} />
        },
        grow: 1,
    },
    {
        id: '6',
        Header: 'Alt',
        accessor: 'alt',
        minWidth: 80,
        Cell: ({ row }: CellProps<any>) => {
            return <ShortenedCell length={10} content={row.original['alt']} />
        },
        grow: 1,
    },
    {
        id: '7',
        Header: 'Length',
        accessor: 'length',
        minWidth: 60,
        grow: 1,
    },
    {
        id: '8',
        Header: 'Most severe transcript',
        accessor: 'transcript_consequence_major.transcript_id',
        minWidth: 160,
        grow: 1,
        Cell: ({ value }: CellProps<any>) => {
            return <Link to={`/transcript/${value}`}>{value}</Link>
        },
    },
    {
        id: '9',
        Header: 'VEP Annotation',
        accessor: 'transcript_consequence_major.consequence',
        Filter: VEPFilter,
        Cell: ({ value }: CellProps<any>) => {
            if(Array.isArray(value)) {
                return <ShortenedCell length={34} content={value.join(', ')} />
            }
            return <ShortenedCell length={34} content={value} />
        },
        filter: 'includesContainsValue',
        minWidth: 210,
        grow: 2,
    },
    //TODO tady je to v prdeli, není to na API
    {
        id: '10',
        Header: 'Allele Frequency',
        accessor: 'czechgenome_genome_frequencies_allAf',
        filter: 'between',
        Filter: AlleleFrequency,
        minWidth: 170,
        grow: 1,
    },
    {
        id: '11',
        Header: 'HGVSC Consequence',
        accessor: 'transcript_consequence_major.hgvsc',
        minWidth: 100,
        grow: 1,
    },
    {
        id: '12',
        Header: 'rsID',
        accessor: 'rsids',
        minWidth: 120,
        grow: 1,
        Cell: ({ value }: CellProps<any>) => {
            return value ? (
                <MuiLink
                    href={`https://www.ncbi.nlm.nih.gov/snp/?term=${value}`}
                    target='_blank'
                >
                    {value}
                </MuiLink>
            ) : null
        },
    },
    {
        id: '13',
        Header: 'Clinical Significance',
        accessor: 'clinical_significance',
        minWidth: 120,
        grow: 1,
        Cell: ({ value }: CellProps<any>) => {
            if(Array.isArray(value)) {
                return <ShortenedCell length={24} content={value.join(', ')} />
            }
            return <ShortenedCell length={24} content={value} />
        },
    },
    {
        id: '14',
        Header: 'Region',
        accessor: (row: any) => {
            return [row.pos, row.pos + (row.length ? row.length : 0)]
        },
        Cell: ({ row }: CellProps<any>) => {
            return <></>
        },
        filter: 'rangeInRange',
        Filter: RegionFilter,
        minWidth: 100,
        grow: 0,
        show: false,
    },
]
