import React, { useMemo } from 'react'
import { Transcript } from '@/generated/graphql'
import { columns } from '@/store/storage/gene'
import DetailTable from '@/components/detail/DetailTable/DetailTable'

type Props = {
    transcript: Transcript
    setData: (data: any) => void
    setVisibleColumns: (value: Array<number>) => void
}

export default function TranscriptPageTable({
    transcript,
    setData,
    setVisibleColumns,
}: Props) {
    const data = useMemo(() => {
        if (transcript.variants?.length) {
            return transcript.variants.map(variant => ({
                ...variant,
            }))
        }
        return []
    }, [])

    return (
        <DetailTable
            columns={columns}
            data={data ? data : []}
            pageType='transcript'
            setData={setData}
            setVisibleColumns={setVisibleColumns}
        />
    )
}
