import * as React from 'react'

export default function Phone() {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width={16.743} height={16.743}>
            <path
                d='M12.278 10.044c-1.116 1.116-1.116 2.232-2.232 2.232s-2.233-1.114-3.349-2.232-2.232-2.23-2.232-3.347 1.116-1.116 2.232-2.232S4.465 0 3.348 0 0 3.344 0 3.344c0 2.232 2.293 6.758 4.465 8.93s6.7 4.465 8.93 4.465c0 0 3.349-2.232 3.349-3.349s-3.349-4.465-4.465-3.349Z'
                fill='currentColor'
            />
        </svg>
    )
}
