import React from 'react'
import Typography from '@mui/material/Typography'
import styled from 'styled-components'
import Box from '@mui/material/Box'
import HpLogo from '@/assets/images/hp-logo.png'
import HpLogoInstitue from '@/assets/images/hp-image-institute.png'
import HpEu from '@/assets/images/hp-eu.png'
import HpMop from '@/assets/images/hp-mop.png'
import Location from '@/assets/icon/Location'
import Email from '@/assets/icon/Email'
import Phone from '@/assets/icon/Phone'
import Link from '@mui/material/Link'
import DocumentTitle from '@/components/common/DocumentTitle'

const HomePageHeading = styled(Typography)`
  margin-top: 65px;
  margin-bottom: 57px;
  text-transform: uppercase;
`

const HomePageContactWrapper = styled.div`
  max-width: 355px;

`

const HomePageLink = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 25px;

  p {
    margin-left: 25px;
  }
`

const HomePageLinkIconWrapper = styled.div`
  display: inline-block;
  width: 23px;
  color: ${props => props.theme.palette.primary.main};
`

const HomeImagesWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: 25px;
  margin-top: 25px;
`

const HomeImage = styled.img`
  float: left;
  margin-right: 60px;
`

export default function HomePage() {
    return (
        <div>
            <DocumentTitle title={'Home'} />
            <div>
                <HomePageHeading variant='h1' align='center'>
                    Etalon of national interpreted genome map for Czech republic
                </HomePageHeading>
            </div>
            <div>
                <div>
                    <HomeImagesWrapper>
                        <div>
                            <img
                                src={HpEu}
                                alt=''
                                style={{ maxWidth: '350px' }}
                            />
                        </div>
                        <div>
                            <Link href='https://www.mpo.cz/' target='_blank'>
                                <img src={HpMop} alt='' />
                            </Link>
                        </div>
                    </HomeImagesWrapper>
                    <Typography variant='body1' paragraph align='center'>
                        Supported by grant: CZ.01.1.02/0.0/0.0/16_084/0010360
                    </Typography>
                    <Typography variant='h2'>
                        Why Czech and Slavic genomes?
                    </Typography>
                    <Typography variant='body1' paragraph>
                        Although the Slavs are the largest European
                        ethnolinguistic group, Slavic and in particular Czech
                        genomes are underrepresented in international genomic
                        databases, which is limiting our abilities to understand
                        and interpret specific gene variations.
                    </Typography>
                    <Typography variant='body1' paragraph>
                        Slavs speak various Slavic languages, belonging to the
                        larger Balto-Slavic branch of the Indo-European
                        languages. Slavs are geographically distributed
                        throughout northern Eurasia, mainly inhabiting Central,
                        Eastern and Southeastern Europe. A large Slavic minority
                        is also scattered across the Baltic states and Central
                        Asia, while a substantial Slavic diaspora is found
                        throughout the Americas, as a result of immigration.
                    </Typography>
                    <Typography variant='body1' paragraph>
                        Consistent with the proximity of their languages, the
                        gene pool of Eastern and Western Slavs shows to be
                        identical and demonstrating significant differences from
                        neighboring Finno-Ugric, Turkic, and North Caucasian
                        peoples. Such genetic homogeneity is somewhat unusual,
                        given such a wide dispersal of Slavic populations,
                        especially Russians. Together they form the basis of the
                        &quot;East European&quot; gene cluster, which also
                        includes non-Slavic Hungarians and Aromanians. Only
                        Northern Russians among East and West Slavs belong to a
                        different, “Northern European” genetic cluster, along
                        with Balts, Germanic and Baltic Finnic peoples (Northern
                        Russian populations are very similar to Balts). However,
                        most of the published genome studies were performed
                        using mapping of very limited genomic areas, typically
                        analyses of Y chromosomes, mDNA, and autosomal marker
                        CCR5de132.
                    </Typography>
                    <Typography variant='h2'>
                        About the Czech Genome/Multiome Project
                    </Typography>
                    <Typography variant='body1' paragraph>
                        Czech Genome/Multiome Project was started in 2017 around
                        group of researchers form the{' '}
                        <Link href='https://www.imtm.cz/' target='_blank'>
                            Institute of Molecular and translational Medicine
                        </Link>
                        ,{' '}
                        <Link href='https://www.imtm.cz/' target='_blank'>
                            Faculty of Medicine and Dentistry
                        </Link>
                        ,{' '}
                        <Link href='https://www.upol.cz/' target='_blank'>
                            Palacky University
                        </Link>{' '}
                        led by Dr.
                        <Link
                            href='https://orcid.org/0000-0002-4834-908X'
                            target='_blank'
                        >
                            Marián Hajdúch
                        </Link>
                        . The first grant project was awarded by the{' '}
                        <Link href='https://www.mpo.cz/' target='_blank'>
                            Ministry of Industry of the Czech Republic
                        </Link>{' '}
                        to boost biotech industry in the Czech Republic. The
                        project Etalon of <strong>N</strong>ational{' '}
                        <strong>I</strong>nterpreted <strong>G</strong>enome{' '}
                        <strong>M</strong>ap in
                        <strong>Cz</strong>ech Republic (ENIGMA) started in 2018
                        when a team of experts from the{' '}
                        <Link href='https://www.iabio.eu/' target='_blank'>
                            Institute of Applied Biotechnologies a.s.
                        </Link>{' '}
                        (IAB) and IMTM carried out for the first time in the
                        Czech Republic a complete
                        <strong>
                            whole-genome sequencing analysis of Czechs
                        </strong>
                        , which will contribute to mapping our central European
                        genome. Within Enigma project, approximately 1.200
                        healthy Czech citizens from Olomouc district were
                        enrolled into the clinical trial “
                        <Link
                            href='https://clinicaltrials.gov/ct2/show/NCT04427163?term=ENIGMA&draw=2&rank=4'
                            target='_blank'
                        >
                            Assessment of Multiomic Profiles in Health and
                            Diseases
                        </Link>
                        ” with the registration number NCT04427163. Out of them,
                        approx. 780 whole genomes were sequenced and 677 are
                        already processed, digitalized, aggregated into
                        anonymized database and included in the Czech digital
                        genome map. One of the results of this project is the
                        tool <strong>Gen Seeker</strong>, a browser of variants
                        detected in Czech population that is freely available to
                        the public.
                    </Typography>
                    <Typography variant='body1' paragraph>
                        As a next step, all individuals enrolled in the study
                        were profiled for plasma metabolome and lipidome using
                        both targeted and untargeted mass spectrometry based
                        analyses. Currently we run untargeted plasma proteome
                        profile to be completed in Q3 2023. Part of this effort
                        was funded by the H2020 project{' '}
                        <Link
                            href='https://eatris.eu/projects/eatris-plus/'
                            target='_blank'
                        >
                            EATRIS- Plus Flagship project in Personalized
                            Medicine
                        </Link>{' '}
                        and{' '}
                        <Link href='https://enoch.fnusa.cz/' target='_blank'>
                            Molecular, Cellular and Clinical Approach to Healthy
                            Ageing
                        </Link>{' '}
                        (ENOCH). To implement the Czech Genome/Multiome project,
                        the researchers use research infrastructure of the{' '}
                        <Link href='https://eatris.eu/' target='_blank'>
                            European Infrastructure for Translational Medicine
                        </Link>{' '}
                        (EATRIS) and its Czech national network{' '}
                        <Link href='https://www.eatris.cz/' target='_blank'>
                            EATRIS-CZ
                        </Link>
                        .
                    </Typography>
                    <Typography variant='body1' paragraph>
                        Researchers behind the project are open to collaborative
                        research proposals and welcome valuable suggestions and
                        comments from research community. For further
                        correspondence please use email contact:{' '}
                        <Link href='mailto:info@imtm.cz'>info@imtm.cz</Link>.
                    </Typography>
                    <HomeImagesWrapper>
                        <div>
                            <Link href='https://www.iabio.eu/' target='_blank'>
                                <img src={HpLogo} alt='' />
                            </Link>
                        </div>
                        <div>
                            <Link href='https://www.imtm.cz/' target='_blank'>
                                <img src={HpLogoInstitue} alt='' />
                            </Link>
                        </div>
                    </HomeImagesWrapper>
                </div>
            </div>
            <Box display='flex' justifyContent='center'>
                <HomePageContactWrapper>
                    <HomePageLink>
                        <HomePageLinkIconWrapper>
                            <Location />
                        </HomePageLinkIconWrapper>
                        <Typography variant='body1'>
                            Institute of Applied Biotechnologies, a.s.
                            <br /> Služeb 3056/4, 108 00 Praha-Strašnice
                        </Typography>
                    </HomePageLink>
                    <HomePageLink>
                        <HomePageLinkIconWrapper>
                            <Email />
                        </HomePageLinkIconWrapper>
                        <Typography variant='body1'>
                            E-mail:&nbsp;
                            <Link href='mailto:iabio@iabio.eu'>
                                iabio@iabio.eu
                            </Link>
                        </Typography>
                    </HomePageLink>
                    <HomePageLink>
                        <HomePageLinkIconWrapper>
                            <Phone />
                        </HomePageLinkIconWrapper>
                        <Typography variant='body1'>
                            Phone:&nbsp;
                            <Link href='tel:+420255700885'>
                                +420 255 700 885
                            </Link>
                        </Typography>
                    </HomePageLink>
                </HomePageContactWrapper>
            </Box>
        </div>
    )
}
