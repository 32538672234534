import React from 'react'
import TableToolbarButton from '@/components/detail/DetailTable/TableToolbar/TableToolbarButton'
import DownloadIcon from '@mui/icons-material/Download'
import { PageType } from '@/store/reducers'
import { getTableDataForExport, makeCsv } from '@/utils/table'

type Props = {
    data: any
    columns: any[]
    pageType: PageType
    visibleData: any[]
}

export default function TableColumnExport({
    data,
    columns,
    pageType,
    visibleData,
}: Props) {
    const handleButtonClick = () => {
        makeCsv(
            getTableDataForExport(
                visibleData.map(row => row.original),
                columns.filter(column => column.show !== false)
            ),
            `${pageType}.csv`
        )
    }

    return (
        <TableToolbarButton onClick={handleButtonClick} icon={<DownloadIcon />}>
            CSV download
        </TableToolbarButton>
    )
}
