import { createStore } from 'redux'
import { persistReducer, createTransform } from 'redux-persist'
import reducer, { InitialStateType } from './reducers'
import storage from 'redux-persist/lib/storage'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'

const errorBlackList = createTransform(null, (state: InitialStateType, key) => {
    const newState = { ...state }
    newState.search.error = false
    return newState
})

const persistConfig = {
    key: 'root',
    storage: storage,
    blacklist: ['navigation'],
    transforms: [errorBlackList],
}

const persistedReducer = persistReducer(persistConfig, reducer)

export default function configureStore() {
    const store = createStore(reducer, composeWithDevTools())
    return { store }
}
