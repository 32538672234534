import React from 'react'

type Props = {
    scalePosition: any
    width: number
}

export default function PositionAxis({ scalePosition, width }: Props) {
    const height = 15
    const numIntervals = Math.min(10, Math.floor(width / 90))
    const lineColor = '#707070'

    const tickInterval = width / numIntervals
    const ticks = [...Array(numIntervals - 1)].map(
        (_, i) => tickInterval * (i + 1)
    )

    return (
        <svg height={height} width={width}>
            <line
                x1={0}
                y1={0}
                x2={width}
                y2={0}
                stroke={lineColor}
                strokeWidth={2}
            />
            <g>
                <line
                    x1={0}
                    y1={0}
                    x2={0}
                    y2={5}
                    stroke={lineColor}
                    strokeWidth={2}
                />
                <text
                    x={0}
                    y={height - 5}
                    textAnchor='start'
                    style={{ fontSize: '10px' }}
                >
                    {scalePosition.invert(0).toLocaleString()}
                </text>
            </g>
            {ticks.map(x => (
                <g key={x}>
                    <line
                        x1={x}
                        y1={0}
                        x2={x}
                        y2={5}
                        stroke={lineColor}
                        strokeWidth={1}
                    />
                    <text
                        x={x}
                        y={height - 5}
                        textAnchor='middle'
                        style={{ fontSize: '10px' }}
                    >
                        {scalePosition.invert(x).toLocaleString()}
                    </text>
                </g>
            ))}
            <g>
                <line
                    x1={width}
                    y1={0}
                    x2={width}
                    y2={5}
                    stroke={lineColor}
                    strokeWidth={2}
                />
                <text
                    x={width}
                    y={height - 5}
                    textAnchor='end'
                    style={{ fontSize: '10px' }}
                >
                    {scalePosition.invert(width).toLocaleString()}
                </text>
            </g>
        </svg>
    )
}
