import React from 'react'
import styled from 'styled-components'
import CoreLink from '@/components/common/Link'
import { LinkProps } from 'react-router-dom'

const Link = styled(CoreLink)`
  text-decoration: none;
  color: inherit;
`

export default function CleanLink(props: LinkProps) {
    return <Link {...props}>{props.children}</Link>
}
