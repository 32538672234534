import React, { PropsWithChildren, useState } from 'react'
import TableFilterDropdown from '@/components/detail/DetailTable/TableToolbar/TableFilters/TableFilterDropdown'
import Slider from '@mui/material/Slider'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import { FilterValue } from 'react-table'
import styled from 'styled-components'
import { scale } from '@/components/detail/DetailTable/TableToolbar/TableFilters/utils'

type Props = {
    id: string
    title: string
    value: any
    setValue: (value: number | number[]) => void
    rangeArray: Array<number>
    marks: Array<{ value: number; label: string; scaledValue?: number }>
    setFilter: (
        updater: ((filterValue: FilterValue) => FilterValue) | FilterValue
    ) => void
    step?: number
    scaled?: boolean
    valueLabelFormat?: (value: number) => string
    onClose?: () => void
}

const TableFilterRangeSlider = styled(Slider)`
  font-size: 10px;

  & .MuiSlider-markLabel {
    font-size: 10px;
  }

  & .MuiSlider-valueLabel {
    padding: 0;
    top: 50px;
    background-color: unset;
    color: ${props => props.theme.palette.black.main};
    font-size: 10px;
    font-weight: bold;
    color: ${props => props.theme.palette.primary.main};
  }

  & .MuiSlider-track {
    color: ${props => props.theme.palette.primary.main};
  }

  & .MuiSlider-rail {
    color: #707070;
    opacity: 1;
  }

  & .MuiSlider-thumb {
    width: 15px;
    height: 15px;

    &:before {
      border: 2px solid ${props => props.theme.palette.primary.main};
      background-color: ${props => props.theme.palette.white.main};
    }

    &[data-index="0"] {
      .MuiSlider-valueLabel {
        right: 0;
      }
    }

    &[data-index="1"] {
      .MuiSlider-valueLabel {
        left: 0;
      }
    }
  }

  & .MuiSlider-markLabel {
    top: -15px;
    color: ${props => props.theme.palette.black.main};
    font-weight: 700;
  }
`

const TableFilterRangeButton = styled(Button)`

`

export default function TableFilterRange({
    id,
    title,
    marks,
    rangeArray,
    setFilter,
    step,
    value,
    setValue,
    valueLabelFormat,
    onClose,
    scaled = true,
}: PropsWithChildren<Props>) {
    const [anchorEl, setAnchorEl] = useState<Element | null>(null)
    const handleChange = (event: Event, newValue: number | number[]) => {
        setValue(newValue)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleButtonClick = () => {
        setFilter(scaled ? scaleValues(value) : value)
        handleClose()
    }

    const scaleValue = (value: number) => {
        return scale(value, marks)
    }

    const scaleValues = (valueArray: any) => {
        return [scaleValue(valueArray[0]), scaleValue(valueArray[1])]
    }

    const _valueLabelFormat = valueLabelFormat
        ? valueLabelFormat
        : (value: number) => {
            return scaled ? scaleValue(value)?.toFixed(4) : value
        }

    return (
        <TableFilterDropdown
            id={id}
            title={title}
            anchorEl={anchorEl}
            onAnchorElChange={setAnchorEl}
            onClose={handleClose}
        >
            <Box width='560px' padding='30px 60px'>
                <TableFilterRangeSlider
                    value={value}
                    onChange={handleChange}
                    valueLabelDisplay='on'
                    getAriaValueText={value => `${value}`}
                    valueLabelFormat={_valueLabelFormat}
                    marks={marks}
                    min={scaled ? 0 : rangeArray[0]}
                    step={step ? step : 1}
                    max={scaled ? 100 : rangeArray[1]}
                />
            </Box>
            <TableFilterRangeButton
                onClick={handleButtonClick}
                variant='contained'
                fullWidth={true}
            >
                Apply
            </TableFilterRangeButton>
        </TableFilterDropdown>
    )
}
