import React, { useEffect, useState } from 'react'
import MuiAppBar from '@mui/material/AppBar'
import Typography from '@mui/material/Typography'
import SearchBox from './common/SearchBox/SearchBox'
import MuiToolbar from '@mui/material/Toolbar'
import styled from 'styled-components'
import CoreCleanLink from '@/components/common/CleanLink'
import Menu from '@/components/Menu'
import MenuIcon from '@mui/icons-material/Menu'
import { Drawer, IconButton, List, ListItemButton } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Link from '@/components/common/Link'
import { useLocation } from 'react-router-dom'

const AppBar = styled(MuiAppBar)`
  min-height: 72px;

  .MuiToolbar-root {
    min-height: 72px;
  }
`

const Toolbar = styled(MuiToolbar)`
  position: relative;
  justify-content: space-between;
`

const CleanLink = styled(CoreCleanLink)`
  position: relative;
  z-index: 10;
`

const HamburgerIcon = styled(MenuIcon)``

export default function MenuAppBar() {
    const theme = useTheme()
    const location = useLocation()
    const matches = useMediaQuery(theme.breakpoints.down('md'))
    const [drawerOpen, setDrawerOpen] = useState(false)

    const onDrawerOpen = () => {
        setDrawerOpen(true)
    }

    const onDrawerClose = () => {
        setDrawerOpen(false)
    }

    useEffect(() => {
        if (drawerOpen) {
            setDrawerOpen(false)
        }
    }, [location])

    return (
        <AppBar position='relative' elevation={0}>
            <Toolbar>
                <CleanLink to='/'>
                    <Typography fontWeight='bold' marginLeft='30px'>
                        Czech digiTAl Genome
                    </Typography>
                </CleanLink>
                {matches ? (
                    <>
                        <IconButton
                            onClick={onDrawerOpen}
                            edge='start'
                            color='inherit'
                        >
                            <HamburgerIcon />
                        </IconButton>
                        <Drawer
                            anchor='right'
                            open={drawerOpen}
                            onClose={onDrawerClose}
                        >
                            <List>
                                <ListItem
                                    disablePadding
                                    component={Link}
                                    to='/'
                                >
                                    <ListItemButton>
                                        <ListItemText primary='Home' />
                                    </ListItemButton>
                                </ListItem>
                                <ListItem
                                    disablePadding
                                    component={Link}
                                    to='/search'
                                >
                                    <ListItemButton>
                                        <ListItemText primary='Search' />
                                    </ListItemButton>
                                </ListItem>
                                <ListItem
                                    disablePadding
                                    component={Link}
                                    to='/data-info'
                                >
                                    <ListItemButton>
                                        <ListItemText primary='Data info' />
                                    </ListItemButton>
                                </ListItem>
                            </List>
                        </Drawer>
                    </>
                ) : (
                    <>
                        <Menu />
                        <SearchBox />
                    </>
                )}
            </Toolbar>
        </AppBar>
    )
}
