import { createTheme as createMuiTheme } from '@mui/material/styles'
import { ThemeColorsHospitalic } from '@/assets/styles/color/ThemeColorsHospitalic'

declare module '@mui/material/styles' {
    interface Palette {
        white: Palette['primary']
        black: Palette['primary']
        red: Palette['primary']
        yellow: Palette['primary']
        green: Palette['primary']
        exome: Palette['primary']
        genome: Palette['primary']
        detailLink: Palette['primary']
    }

    interface PaletteOptions {
        white: PaletteOptions['primary']
        black: PaletteOptions['primary']
        red: PaletteOptions['primary']
        yellow: PaletteOptions['primary']
        green: PaletteOptions['primary']
        exome: PaletteOptions['primary']
        genome: PaletteOptions['primary']
        detailLink: PaletteOptions['primary']
    }
}

const themeEnum = ThemeColorsHospitalic

export default function createTheme() {
    return createMuiTheme({
        typography: {
            fontFamily: '\'IBM Plex Sans\', sans-serif',
            h1: {
                fontSize: '24px',
                fontWeight: 'bold',
            },
            h2: {
                fontSize: '16px',
                fontWeight: 'bold',
            },
            h3: {
                fontSize: '16px',
                fontWeight: 'bold',
            },
            fontSize: 14,
        },
        palette: {
            primary: {
                main: themeEnum.PRIMARY,
                light: themeEnum.PRIMARY_LIGHT,
                '300': themeEnum.PRIMARY_300,
                '400': themeEnum.TABLES,
            },
            text: {
                primary: themeEnum.TEXT_PRIMARY,
                secondary: themeEnum.TEXT_SECONDARY,
            },
            white: {
                main: '#fff',
            },
            black: {
                main: '#000',
            },
            red: {
                main: '#CC003E',
            },
            yellow: {
                main: '#FF9300',
            },
            green: {
                main: '#15B900',
            },
            exome: {
                main: 'rgba(83, 203, 203, 0.5)',
            },
            genome: {
                main: 'rgb(186, 173, 154, 0.5)',
            },
            detailLink: {
                main: themeEnum.DETAIL_LINK,
            },
        },

        components: {
            MuiAppBar: {
                styleOverrides: {
                    colorPrimary: {
                        backgroundColor: themeEnum.APP_BAR,
                    },
                },
            },
            MuiDivider: {
                styleOverrides: {
                    root: {
                        backgroundColor: themeEnum.PRIMARY,
                    },
                },
            },
            MuiButton: {
                styleOverrides: {
                    containedPrimary: {
                        color: '#fff',
                        fontWeight: 'bold',
                        textTransform: 'uppercase',
                    },
                },
            },

            /*Tables*/
            MuiTableCell: {
                styleOverrides: {
                    root: {
                        border: `1px solid ${themeEnum.TABLES}`,
                    },
                },
            },
            MuiTable: {
                styleOverrides: {
                    root: {
                        borderRadius: '5px',
                        border: `1px solid ${themeEnum.TABLES}`,
                    },
                },
            },
        },
    })
}
