export enum ThemeColorsHospitalic {
    PRIMARY = '#006D77',
    PRIMARY_LIGHT = '#ddeceb',
    PRIMARY_300 = '#edf6f9',
    SECONDARY = '',
    TEXT_PRIMARY = '#656868',
    TEXT_SECONDARY = '#ffffff',

    // Components:
    APP_BAR = '#006D77',
    DETAIL_LINK = '#53CBCB',
    TABLES = '#ceece9',
}
