//import { gql } from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type AlleleStat = {
  __typename?: 'AlleleStat';
  allele_count?: Maybe<Scalars['Int']>;
  allele_frequency?: Maybe<Scalars['Float']>;
  allele_number?: Maybe<Scalars['Int']>;
  homozygote_count?: Maybe<Scalars['Int']>;
};

export type Coverage = {
  __typename?: 'Coverage';
  mean?: Maybe<Scalars['Float']>;
  pos?: Maybe<Scalars['Int']>;
};

export type DatasetInfo = {
  __typename?: 'DatasetInfo';
  dataset_id?: Maybe<Scalars['String']>;
  dataset_name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  gene_count?: Maybe<Scalars['Int']>;
  num_of_samples?: Maybe<Scalars['Int']>;
  num_of_samples_XX?: Maybe<Scalars['Int']>;
  num_of_samples_XY?: Maybe<Scalars['Int']>;
  reference_genome?: Maybe<Scalars['String']>;
  transcript_count?: Maybe<Scalars['Int']>;
  variant_count?: Maybe<Scalars['Int']>;
  version?: Maybe<Scalars['String']>;
};

export type Gene = {
  __typename?: 'Gene';
  canonical_transcript_id?: Maybe<Scalars['String']>;
  chrom: Scalars['String'];
  coverage_exome?: Maybe<Array<Maybe<Coverage>>>;
  coverage_genome?: Maybe<Array<Maybe<Coverage>>>;
  gene_id: Scalars['String'];
  gene_symbol?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  start: Scalars['Int'];
  stop: Scalars['Int'];
  transcript_ids?: Maybe<Array<Maybe<Scalars['String']>>>;
  variants?: Maybe<Array<Maybe<Variant>>>;
};

export type GeneQueryResult = {
  __typename?: 'GeneQueryResult';
  gene_id: Scalars['String'];
  gene_symbol?: Maybe<Scalars['String']>;
};

export type Histogram = {
  __typename?: 'Histogram';
  bin_edges?: Maybe<Array<Maybe<Scalars['String']>>>;
  bin_freq?: Maybe<Array<Maybe<Scalars['Float']>>>;
};

export type Population = {
  __typename?: 'Population';
  allele_stats?: Maybe<AlleleStat>;
  population_subtype?: Maybe<Scalars['String']>;
  variant_id?: Maybe<Scalars['String']>;
};

export type PopulationFrequencies = {
  __typename?: 'PopulationFrequencies';
  population_source?: Maybe<PopulationSourceType>;
  populations?: Maybe<Array<Maybe<Population>>>;
  source?: Maybe<SourceType>;
  variant_id?: Maybe<Scalars['String']>;
};

export enum PopulationSourceType {
  FrequenciesExomeAlleleStats = 'frequencies_exome_allele_stats',
  FrequenciesGenomeAlleleStats = 'frequencies_genome_allele_stats',
  FrequenciesGnomad = 'frequencies_gnomad',
  FrequenciesGnomadExom = 'frequencies_gnomad_exom',
  FrequenciesOneKg = 'frequencies_oneKg'
}

export type Query = {
  __typename?: 'Query';
  dataset?: Maybe<DatasetInfo>;
  datasetList?: Maybe<Array<Maybe<DatasetInfo>>>;
  gene?: Maybe<Gene>;
  geneSearch?: Maybe<Array<Maybe<GeneQueryResult>>>;
  region?: Maybe<Region>;
  regionCount?: Maybe<Scalars['Int']>;
  transcript?: Maybe<Transcript>;
  variant?: Maybe<VariantDetails>;
  variantSearch?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QueryDatasetArgs = {
  dataset_id?: InputMaybe<Scalars['String']>;
};


export type QueryGeneArgs = {
  dataset_id?: InputMaybe<Scalars['String']>;
  gene_id?: InputMaybe<Scalars['String']>;
  gene_symbol?: InputMaybe<Scalars['String']>;
};


export type QueryGeneSearchArgs = {
  dataset_id?: InputMaybe<Scalars['String']>;
  query: Scalars['String'];
};


export type QueryRegionArgs = {
  chrom: Scalars['String'];
  dataset_id?: InputMaybe<Scalars['String']>;
  start: Scalars['Int'];
  stop: Scalars['Int'];
};


export type QueryRegionCountArgs = {
  chrom: Scalars['String'];
  dataset_id?: InputMaybe<Scalars['String']>;
  start: Scalars['Int'];
  stop: Scalars['Int'];
};


export type QueryTranscriptArgs = {
  dataset_id?: InputMaybe<Scalars['String']>;
  transcript_id: Scalars['String'];
};


export type QueryVariantArgs = {
  dataset_id?: InputMaybe<Scalars['String']>;
  variant_id: Scalars['String'];
};


export type QueryVariantSearchArgs = {
  dataset_id?: InputMaybe<Scalars['String']>;
  variant_id: Scalars['String'];
};

export type Region = {
  __typename?: 'Region';
  chrom: Scalars['String'];
  coverage_exome?: Maybe<Array<Maybe<Coverage>>>;
  coverage_genome?: Maybe<Array<Maybe<Coverage>>>;
  gene_ids?: Maybe<Array<Maybe<Scalars['String']>>>;
  gene_symbols?: Maybe<Array<Maybe<Scalars['String']>>>;
  start: Scalars['Int'];
  stop: Scalars['Int'];
  variants?: Maybe<Array<Maybe<Variant>>>;
};

export enum SourceType {
  Exome = 'exome',
  Genome = 'genome'
}

export type Transcript = {
  __typename?: 'Transcript';
  chrom: Scalars['String'];
  coverage_exome?: Maybe<Array<Maybe<Coverage>>>;
  coverage_genome?: Maybe<Array<Maybe<Coverage>>>;
  gene_id?: Maybe<Scalars['String']>;
  gene_symbol?: Maybe<Scalars['String']>;
  start: Scalars['Int'];
  stop: Scalars['Int'];
  transcript_id: Scalars['String'];
  variants?: Maybe<Array<Maybe<Variant>>>;
};

export type TranscriptConsequence = {
  __typename?: 'TranscriptConsequence';
  consequence?: Maybe<Array<Maybe<Scalars['String']>>>;
  gene_id: Scalars['String'];
  gene_symbol?: Maybe<Scalars['String']>;
  hgvsc?: Maybe<Scalars['String']>;
  hgvsp?: Maybe<Scalars['String']>;
  is_canonical?: Maybe<Scalars['Boolean']>;
  transcript_id: Scalars['String'];
};

export type Variant = {
  __typename?: 'Variant';
  alt: Scalars['String'];
  chrom: Scalars['String'];
  clinical_significance?: Maybe<Array<Maybe<Scalars['String']>>>;
  czechgenome_genome_frequencies_allAc?: Maybe<Scalars['Int']>;
  czechgenome_genome_frequencies_allAf?: Maybe<Scalars['Float']>;
  czechgenome_genome_frequencies_allAn?: Maybe<Scalars['Int']>;
  czechgenome_genome_frequencies_allHc?: Maybe<Scalars['Int']>;
  czechgenome_genome_frequencies_femaleAc?: Maybe<Scalars['Int']>;
  czechgenome_genome_frequencies_femaleAf?: Maybe<Scalars['Float']>;
  czechgenome_genome_frequencies_femaleAn?: Maybe<Scalars['Int']>;
  czechgenome_genome_frequencies_femaleHc?: Maybe<Scalars['Int']>;
  czechgenome_genome_frequencies_maleAc?: Maybe<Scalars['Int']>;
  czechgenome_genome_frequencies_maleAf?: Maybe<Scalars['Float']>;
  czechgenome_genome_frequencies_maleAn?: Maybe<Scalars['Int']>;
  czechgenome_genome_frequencies_maleHc?: Maybe<Scalars['Int']>;
  gene_ids?: Maybe<Array<Maybe<Scalars['String']>>>;
  gene_symbols?: Maybe<Array<Maybe<Scalars['String']>>>;
  length?: Maybe<Scalars['Int']>;
  pos: Scalars['Int'];
  ref: Scalars['String'];
  rsids?: Maybe<Array<Maybe<Scalars['String']>>>;
  source?: Maybe<Array<Maybe<SourceType>>>;
  transcript_consequence_major?: Maybe<TranscriptConsequence>;
  transcript_id_canonical?: Maybe<Array<Maybe<Scalars['String']>>>;
  variant_id: Scalars['String'];
};

export type VariantDetails = {
  __typename?: 'VariantDetails';
  age_distribution_exome?: Maybe<Histogram>;
  age_distribution_genome?: Maybe<Histogram>;
  alt: Scalars['String'];
  chrom: Scalars['String'];
  clinical_significance?: Maybe<Array<Maybe<Scalars['String']>>>;
  coverage_exome?: Maybe<Coverage>;
  coverage_genome?: Maybe<Coverage>;
  gene_ids?: Maybe<Array<Maybe<Scalars['String']>>>;
  gene_symbols?: Maybe<Array<Maybe<Scalars['String']>>>;
  length?: Maybe<Scalars['Int']>;
  population_frequencies?: Maybe<Array<Maybe<PopulationFrequencies>>>;
  pos: Scalars['Int'];
  ref: Scalars['String'];
  rsids?: Maybe<Array<Maybe<Scalars['String']>>>;
  source?: Maybe<Array<Maybe<SourceType>>>;
  transcript_consequence?: Maybe<Array<Maybe<TranscriptConsequence>>>;
  transcript_consequence_major?: Maybe<TranscriptConsequence>;
  variant_id: Scalars['String'];
};
