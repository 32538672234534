import React from 'react'
import Typography from '@mui/material/Typography'
import styled from 'styled-components'
import {
    CategoryType,
    getCategoryColor,
} from '@/components/detail/DetailTable/TableToolbar/TableFilters/VEPFilter/VEPFilter'
import CoreCheckboxLabel from '@/components/common/CheckboxLabel'

type Props = {
    category: CategoryType
    title: string
    checked: boolean
    onCheckBoxChange: (value: boolean) => void
}

const VEPFilterItemWrapper = styled.div`
  display: flex;
`

const CheckboxLabel = styled(CoreCheckboxLabel)`
  && .MuiCheckbox-root {
    svg {
      width: 10px;
      height: 10px;
      color: ${props => props.theme.palette.primary.main};
    }
  }
`

export default function VEPFilterItem({
    checked,
    onCheckBoxChange,
    title,
    category,
}: Props) {
    const handleCheckBoxChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        onCheckBoxChange(event.target.checked)
    }
    return (
        <VEPFilterItemWrapper>
            <CheckboxLabel
                title={
                    <Typography
                        variant='body1'
                        color={getCategoryColor(category)}
                    >
                        {title}
                    </Typography>
                }
                checked={checked}
                onChange={handleCheckBoxChange}
            />
        </VEPFilterItemWrapper>
    )
}
