import React from 'react'
import { Gene } from '@/generated/graphql'
import DetailTrack from '@/components/detail/DetailTrack/DetailTrack'

type Props = {
    gene: Gene
    visibleVariant: Array<number>
    tableData: any
}

export default function GenePageTrack({
    gene,
    visibleVariant,
    tableData,
}: Props) {
    let data

    if (gene.variants?.length) {
        data = gene.variants.map(variant => ({
            ...variant,
            gene: gene.gene_symbol,
        }))
    } else {
        data = gene.variants
    }

    return (
        <DetailTrack
            variants={data}
            variantsInTable={tableData}
            start={gene.start}
            stop={gene.stop}
            genomeCoverage={gene.coverage_genome}
            exomeCoverage={gene.coverage_exome}
            visibleVariant={visibleVariant}
        />
    )
}
