// reference: https://github.com/broadinstitute/gnomad-browser-toolkit/blob/main/packages/track-variants/src/VariantPlot.js
import React, { forwardRef, useCallback, useEffect, useRef } from 'react'

const useCombinedRefs = (refs: any[]) =>
    useCallback(element => {
        refs.forEach(ref => {
            if (!ref) {
                return
            }

            if (typeof ref === 'function') {
                ref(element)
            } else {
                ref.current = element // eslint-disable-line no-param-reassign
            }
        })
    }, refs)

type Props = {
    children: (arg0: CanvasRenderingContext2D) => void
    height: number
    width: number
}

const Canvas = forwardRef<HTMLCanvasElement, Props>(
    ({ children, height, width, ...otherProps }, ref) => {
        const element = useRef<HTMLCanvasElement>(null)
        const refs = useCombinedRefs([element, ref])

        const scale = window.devicePixelRatio || 1

        useEffect(() => {
            if (!element.current) {
                return
            }

            const context = element.current.getContext('2d')
            if (context) {
                context?.setTransform(scale, 0, 0, scale, 0, 0)
                children(context)
            }
        })

        return (
            <canvas
                {...otherProps}
                ref={refs}
                height={height * scale}
                width={width * scale}
                style={{
                    height: `${height}px`,
                    width: `${width}px`,
                }}
            />
        )
    }
)

Canvas.displayName = 'Canvas'

export default Canvas
