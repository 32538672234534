import { useEffect } from 'react'

type Props = {
    title?: string
}

export default function DocumentTitle({ title }: Props) {
    useEffect(() => {
        document.title = title
            ? `${title} | Czech digiTAl Genome`
            : 'Czech digiTAl Genome'
    }, [title])
    return null
}
