export const unscale = (
    value: number | undefined,
    marks: Array<{ value: number; label: string; scaledValue: number }>
) => {
    if (value === undefined) {
        return undefined
    }
    const markScaledValues = marks.map(mark => mark.scaledValue)
    let previousMarkIndex = 0
    let remainder = 0
    for (const [i, markValue] of markScaledValues.entries()) {
        if (markValue <= value && value < markScaledValues[i + 1]) {
            previousMarkIndex = i
            remainder = value - markValue
        } else if (value === markValue) {
            previousMarkIndex = i
            remainder = 0
        } else if (value === markScaledValues.slice(-1)[0]) {
            previousMarkIndex = markScaledValues.length - 1
            remainder = 0
        }
    }

    const previousMark = marks[previousMarkIndex]
    if (remainder === 0) {
        return previousMark.value
    }
    const nextMark = marks[previousMarkIndex + 1]

    if (
        nextMark.value &&
        previousMark.value &&
        previousMark.scaledValue &&
        nextMark.scaledValue
    ) {
        return (
            (remainder * (nextMark.value - previousMark.value)) /
                (nextMark.scaledValue - previousMark.scaledValue) +
            previousMark.value
        )
    }
}

export const scale = (
    value: number | undefined,
    marks: Array<{ value: number; label: string; scaledValue?: number }>
) => {
    if (value === undefined) {
        return undefined
    }
    const markValues = marks.map(mark => mark.value)
    let previousMarkIndex = 0
    let remainder = 0

    for (const [i, markValue] of markValues.entries()) {
        if (markValue <= value && value < markValues[i + 1]) {
            previousMarkIndex = i
            remainder = value - markValue
        } else if (value === markValue) {
            previousMarkIndex = i
            remainder = 0
        } else if (value === markValues.slice(-1)[0]) {
            previousMarkIndex = markValues.length - 1
            remainder = 0
        }
    }

    const previousMark = marks[previousMarkIndex]
    if (remainder === 0) {
        return previousMark.scaledValue
    }
    const nextMark = marks[previousMarkIndex + 1]
    if (nextMark.scaledValue && previousMark.scaledValue) {
        return (
            (remainder * (nextMark.scaledValue - previousMark.scaledValue)) /
                (nextMark.value - previousMark.value) +
            previousMark.scaledValue
        )
    }
}
