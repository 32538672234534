import { FilterValue, Row } from 'react-table'

const includesContainsValue = <T extends Record<string, unknown>>(
    rows: Array<Row<T>>,
    columnIds: any,
    filterValue: FilterValue
) => {
    return rows.filter(row => {
        return columnIds.some((id: any) => {
            const rowValue = row.values[id]
            return filterValue.some((val: string) => rowValue?.includes(val))
        })
    })
}

export default includesContainsValue
