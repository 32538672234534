import React from 'react'

import {
    isRegionId,
    normalizeRegionId,
    parseRegionId,
} from '@gnomad/identifiers'
import RegionPage from './RegionPage'
import BaseQuery from '../../BaseQuery'
import { BaseQueryChildrenType } from '../../types/BaseQueryChildrenType'
import { gql } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { REGION_LENGTH_QUERY } from '@/graphql/query/regionCount'
import Alert from '@mui/material/Alert'

const query = gql`
    query Region($chrom: String!, $start: Int!, $stop: Int!) {
        region(chrom: $chrom, start: $start, stop: $stop) {
            chrom
            start
            stop
            gene_ids
            gene_symbols
            variants {
                chrom
                ref
                alt
                length
                transcript_id_canonical
                source
                czechgenome_genome_frequencies_allAf
                czechgenome_genome_frequencies_allAc
                czechgenome_genome_frequencies_allAn
                czechgenome_genome_frequencies_allHc
                czechgenome_genome_frequencies_maleAf
                czechgenome_genome_frequencies_maleAc
                czechgenome_genome_frequencies_maleAn
                czechgenome_genome_frequencies_maleHc
                czechgenome_genome_frequencies_femaleAf
                czechgenome_genome_frequencies_femaleAc
                czechgenome_genome_frequencies_femaleAn
                czechgenome_genome_frequencies_femaleHc
                transcript_consequence_major {
                    gene_symbol
                    gene_id
                    consequence
                    transcript_id
                }
                source
                pos
                variant_id
                clinical_significance
                rsids
            }
        }
    }
`

type Params = {
    regionId: string
}

export default function RegionPageContainer() {
    const { regionId = '' } = useParams<Params>()

    const normalizedRegionId = normalizeRegionId(regionId)

    if (!isRegionId(regionId)) {
        return <></>
    }

    const { chrom, start, stop } = parseRegionId(normalizedRegionId)

    const variables = {
        chrom,
        start,
        stop,
    }

    return (
        <BaseQuery
            query={REGION_LENGTH_QUERY}
            variables={variables}
            loadingMessage='Loading region'
        >
            {({ data }: BaseQueryChildrenType) => {
                if (data.regionCount > 10000) {
                    return (
                        <Alert severity='error'>
                            Entered range is too big!
                        </Alert>
                    )
                } else {
                    return (
                        <BaseQuery
                            query={query}
                            variables={variables}
                            loadingMessage='Loading region'
                        >
                            {({ data }: BaseQueryChildrenType) => {
                                return (
                                    <RegionPage
                                        region={{
                                            ...data.region,
                                            chrom,
                                            start,
                                            stop,
                                        }}
                                    />
                                )
                            }}
                        </BaseQuery>
                    )
                }
            }}
        </BaseQuery>
    )
}
