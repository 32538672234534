import React from 'react'

import styled from 'styled-components'
import MenuItem from '@/components/MenuItem'

const MenuWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  right: 0;
  height: 100%;
`

export default function Menu() {
    return (
        <MenuWrapper>
            <MenuItem to='/' title='Home' />
            <MenuItem to='/search' title='Search' />
            <MenuItem to='/data-info' title='Data info' />
        </MenuWrapper>
    )
}
