import { transparentize } from 'polished'
import React, { PureComponent } from 'react'
import { Track } from '@gnomad/region-viewer'

import styled from 'styled-components'
import VariantPlot from '@/components/detail/DetailTrack/VariantTrack/VariantTrackPlot'
import { Variant } from '@/generated/graphql'
import {
    CategoryType,
    getCategoryColor,
} from '@/components/detail/DetailTable/TableToolbar/TableFilters/VEPFilter/VEPFilter'
import { getVEPCategory } from '@/components/detail/DetailTable/TableToolbar/TableFilters/VEPFilter/filterItems'

const consequenceCategoryColors = {
    lof: transparentize(0.3, '#FF583F'),
    missense: transparentize(0.3, '#F0C94D'),
    synonymous: transparentize(0.3, 'green'),
    other: transparentize(0.3, '#757575'),
}

type Props = {
    title: string
    variants: any
}

const variantColor = (variant: Variant) => {
    return (
        getCategoryColor(
            getVEPCategory(
                (variant.transcript_consequence_major?.consequence
                    ? variant.transcript_consequence_major?.consequence
                    : '') as any
            ) as CategoryType
        ) || '#656868'
    )
}

const BaseVariantTrackWrapper = styled.div`
  & > div > div {
    flex-direction: column;
    font-size: 10px;
    color: ${props => props.theme.palette.black.main};
    font-weight: 700;
    white-space: nowrap;
  }
`

class VariantTrack extends PureComponent<Props> {
    render() {
        return (
            <BaseVariantTrackWrapper>
                <Track title={this.props.title}>
                    {({
                        scalePosition,
                        width,
                    }: {
                        scalePosition: any
                        width: any
                    }) => (
                        <VariantPlot
                            scalePosition={scalePosition}
                            width={width}
                            variants={this.props.variants}
                            variantColor={variantColor}
                        />
                    )}
                </Track>
            </BaseVariantTrackWrapper>
        )
    }
}

export default VariantTrack
