import React from 'react'
import { TranscriptConsequence, VariantDetails } from '@/generated/graphql'
import groupBy from 'lodash.groupby'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import MuiTableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import DetailLink from '@/components/detail/DetailLink'
import styled from 'styled-components'

type Props = {
    variant: VariantDetails
}

const TableCell = styled(MuiTableCell)`
  & {
    padding: 5px 10px;
  }
`

export default function VariantPageEffectPredictor({ variant }: Props) {
    const groupedConsequences = groupBy(
        variant.transcript_consequence,
        'vep_consequence'
    )

    return (
        <TableContainer>
            <Typography variant='body1' fontWeight='bold' color='black.main'>
                Variant Effect Predictor:
            </Typography>
            <Table>
                <TableBody>
                    {Object.keys(groupedConsequences).map(consequenceKey => {
                        const consequences: Array<TranscriptConsequence | null> =
                            groupedConsequences[consequenceKey]
                        return consequences.map((consequence, index) => {
                            return (
                                <TableRow key={index}>
                                    <TableCell>
                                        <DetailLink
                                            to={`/transcript/${consequence?.transcript_id}`}
                                        >
                                            {consequence?.transcript_id}
                                        </DetailLink>
                                    </TableCell>
                                    <TableCell>
                                        HGSVC: {consequence?.hgvsc}
                                    </TableCell>
                                    {index === 0 && (
                                        <TableCell
                                            rowSpan={consequences.length}
                                        >
                                            {consequenceKey}
                                        </TableCell>
                                    )}
                                </TableRow>
                            )
                        })
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    )
}
