import React from 'react'
import { Transcript } from '@/generated/graphql'
import DetailTrack from '@/components/detail/DetailTrack/DetailTrack'

type Props = {
    transcript: Transcript
    visibleVariant: Array<number>
    tableData: any
}

export default function TranscriptPageTrack({
    transcript,
    visibleVariant,
    tableData,
}: Props) {
    let data

    if (transcript.variants?.length) {
        data = transcript.variants.map(variant => ({ ...variant }))
    } else {
        data = transcript.variants
    }

    return (
        <DetailTrack
            variants={data}
            start={transcript.start}
            stop={transcript.stop}
            genomeCoverage={transcript.coverage_genome}
            exomeCoverage={transcript.coverage_exome}
            visibleVariant={visibleVariant}
            variantsInTable={tableData}
        />
    )
}
