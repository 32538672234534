import {
    isRegionId,
    isVariantId,
    normalizeVariantId,
    parseRegionId,
    isRsId,
} from '@gnomad/identifiers'

import { isStructuralVariantId } from './identifiers'
import { ApolloClient, gql } from '@apollo/client'
import { Dispatch } from 'react'
import { TOGGLE_SEARCH_ERROR } from '@/store/types'
import { REGION_LENGTH_QUERY } from '@/graphql/query/regionCount'
import { QueryGeneArgs } from '@/generated/graphql'

export const fetchSearchResults = (
    query: string,
    apolloClient: ApolloClient<any>,
    dispatch: Dispatch<any>
) => {
    // ==============================================================================================
    // Structural Variants
    // ==============================================================================================

    if (isStructuralVariantId(query)) {
        const structuralVariantId = query.toUpperCase()
        return Promise.resolve([
            {
                label: structuralVariantId,
                value: `/variant/${structuralVariantId}`,
            },
        ])
    }
    // ==============================================================================================
    // Variants
    // ==============================================================================================

    if (isVariantId(query)) {
        const variantId = normalizeVariantId(query)
        return Promise.resolve([
            {
                label: variantId,
                value: `/variant/${variantId}`,
            },
        ])
    }

    if (isRsId(query)) {
        const rsId = query
        return Promise.resolve([
            {
                label: rsId,
                value: `/variant/${rsId}`,
            },
        ])
    }

    if (/^CA[0-9]+$/i.test(query)) {
        const caid = query.toUpperCase()
        return Promise.resolve([
            {
                label: caid,
                value: `/variant/${caid}`,
            },
        ])
    }

    if (/^[0-9]+$/.test(query)) {
        const clinvarVariationId = query
        return Promise.resolve([
            {
                label: clinvarVariationId,
                value: `/variant/${clinvarVariationId}`,
            },
        ])
    }

    // ==============================================================================================
    // Region
    // ==============================================================================================
    if (isRegionId(query)) {
        const { chrom, start, stop } = parseRegionId(query)
        const regionId = `${chrom}-${start}-${stop}`

        return apolloClient
            .query<{ regionCount: number }>({
                query: REGION_LENGTH_QUERY,
                variables: {
                    chrom,
                    start,
                    stop,
                },
            })
            .then(data => {
                if (data.data.regionCount) {
                    if (data.data.regionCount < 10000) {
                        const results = [
                            {
                                label: regionId,
                                value: `/region/${regionId}`,
                            },
                        ]

                        // If a position is entered, return options for a 40 base region centered
                        // at the position and the position as a one base region.
                        if (start === stop) {
                            const windowRegionId = `${chrom}-${Math.max(
                                1,
                                start - 20
                            )}-${stop + 20}`
                            results.unshift({
                                label: windowRegionId,
                                value: `/region/${windowRegionId}`,
                            })
                        }
                        return results
                    } else {
                        dispatch({ type: TOGGLE_SEARCH_ERROR })
                    }
                }
                return []
            })
    }

    // ==============================================================================================
    // Gene ID
    // ==============================================================================================

    const upperCaseQuery = query.toUpperCase()

    if (/^ENSG\d{11}$/.test(upperCaseQuery)) {
        const geneId = upperCaseQuery
        return Promise.resolve([
            {
                label: geneId,
                value: `/gene/${geneId}`,
            },
        ])
    }

    // ==============================================================================================
    // Transcript ID
    // ==============================================================================================

    if (/^ENST\d{11}$/.test(upperCaseQuery)) {
        const transcriptId = upperCaseQuery
        return Promise.resolve([
            {
                label: transcriptId,
                value: `/transcript/${transcriptId}`,
            },
        ])
    }

    const GENE_QUERY = gql`
      query GeneSearch($query: String!) {
          geneSearch(query: $query) {
              gene_id
              gene_symbol
          }
      }
  `

    // ==============================================================================================
    // Gene id
    // ==============================================================================================
    if (/^[A-Z][A-Z0-9-]*$/.test(upperCaseQuery)) {
        return apolloClient
            .query<{ geneSearch: QueryGeneArgs[] }>({
                query: GENE_QUERY,
                variables: {
                    query,
                },
            })
            .then(data => {
                return data.data.geneSearch.map(({ gene_id, gene_symbol }) => {
                    return {
                        value: `/gene/${gene_id}`,
                        label: gene_symbol
                            ? gene_symbol
                            : gene_id
                                ? gene_id
                                : '',
                    }
                })
            })
    }

    return Promise.resolve([])
}
