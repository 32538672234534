import {PopulationSourceType, VariantDetails} from '@/generated/graphql'
import React, {useEffect, useRef, useState} from 'react'
import styled from 'styled-components'
import MuiTableContainer from '@mui/material/TableContainer'
import TableBody from '@mui/material/TableBody'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import {marks} from '@/components/detail/DetailTable/TableToolbar/TableFilters/AlleleFrequency/AlleleFrequency'
import {unscale} from '@/components/detail/DetailTable/TableToolbar/TableFilters/utils'

import TableFooter from '@mui/material/TableFooter'

import {
    TableCell as CoreTableCell,
    Table,
    TableHeadCell,
    TableSortLabel as CoreTableSortLabel, TablePopulationCellSubHeader,
} from '@/components/detail/DetailTable/TableComponents'
import VariantPagePopulationFrequenciesCellBar
    from '@/components/pages/VariantPage/VariantPagePopulationFrequencies/VariantPagePopulationFrequenciesCellBar'
import VariantPagePopulationFrequenciesHeadCellBar
    from '@/components/pages/VariantPage/VariantPagePopulationFrequencies/VariantPagePopulationFrequenciesHeadCellBar'
import {GNOMAD_POPULATION_NAMES} from '@/utils/populationNames'
import {ThemeColorsHospitalic} from '@/assets/styles/color/ThemeColorsHospitalic'
import dashIfUndefined from '@/components/pages/VariantPage/dashIfUndefined'

const POP_FREQ_ROW_NAMES: Record<string, string> = {
    'frequencies_genome_allele_stats': 'Frequencies in Czech population',
    'frequencies_gnomad': 'Frequencies in GNOMAD'
}


const TableSortLabel = styled(CoreTableSortLabel)`
  &&.MuiTableSortLabel-root {
    font-weight: 400;
    text-align: left;
    justify-content: flex-end;

    svg {
      color: ${props => props.theme.palette.primary.main};
    }
  }

  && svg {
    transform: rotate(-90deg);
    opacity: 1;
  }

  &&.Mui-active svg {
    transform: rotate(0deg);
  }
`

const TableContainer = styled(MuiTableContainer)`
  margin-top: 40px;
  width: 100%;
  overflow-x: scroll;
`

const TableCell = styled(CoreTableCell)`
  && {
      color: ${ThemeColorsHospitalic.TEXT_PRIMARY};
    padding: 5px 0 5px 5px;
  }
`

const IndentedTableCell = styled(CoreTableCell)`
  && {
      color: ${ThemeColorsHospitalic.TEXT_PRIMARY};
    padding: 5px 0 5px 10px;
  }
`

type Props = {
    variant: VariantDetails
}

export default function VariantPagePopulationFrequencies({variant}: Props) {
    const population_frequencies = variant?.population_frequencies
    const [alleleFrequencyValues, setAlleleFrequencyValues] = useState<
        Record<string, number>
    >({})
    const [expandedPopulations, setExpandedPopulations] = useState<
        Record<string, boolean>
    >({})

    const ref = useRef<HTMLTableCellElement>(null)

    const countChartSize = (frequency: number) => {
        const value = unscale(frequency, marks)

        if (
            ref.current &&
            ref.current.offsetWidth &&
            value !== null &&
            value !== undefined
        ) {
            const containerWidth = ref.current.offsetWidth
            const containerWidthCounted = containerWidth - 50
            return (containerWidthCounted / 100) * value
        }
        return 20
    }

    const renderedPopulations = population_frequencies
        ? population_frequencies.map(population_frequence => {
            const all = population_frequence?.populations?.find(p => p?.population_subtype === 'all')?.allele_stats || {}
            const xx = population_frequence?.populations?.find(p => p?.population_subtype === 'female')?.allele_stats || {}
            const xy = population_frequence?.populations?.find(p => p?.population_subtype === 'male')?.allele_stats || {}

            const alleleFrequencyAll = all.allele_frequency

            const alleleFrequencyXX =
                xx.allele_frequency

            const alleleFrequencyXY =
                xy.allele_frequency

            return {
                ...population_frequence,
                populations: [
                    {
                        population_subtype: 'all',
                        ...all,
                        allele_frequency: alleleFrequencyAll,
                    },
                    {
                        population_subtype: 'female',
                        ...xx,
                        allele_frequency: alleleFrequencyXX,
                    },
                    {
                        population_subtype: 'male',
                        ...xy,
                        allele_frequency: alleleFrequencyXY,
                    },
                ],
            }
        })
        : []

    const countValue = (propertyName: 'allele_number' | 'allele_count') => {
        return renderedPopulations
            .map(population_frequence =>
                population_frequence?.populations
                    ?.filter(pop => population_frequence.population_source === 'frequencies_genome_allele_stats' && pop.population_subtype === 'all')
                    .map(pop => pop[propertyName])
            )
            .reduce((acc, n) => {
                return (
                    acc +
                    (n
                        ? n.reduce<any>(
                            (innerAcc, x) =>
                                (innerAcc ? innerAcc : 0) + (x ? x : 0),
                            0
                        )
                        : 0)
                )
            }, 0)
    }

    // XX/XY numbers are included in the ancestry populations.
    const totalAlleleCount = countValue('allele_count')
    const totalAlleleNumber = countValue('allele_number')
    const totalAlleleFrequency =
        totalAlleleNumber !== 0 ? totalAlleleCount / totalAlleleNumber : 0

    useEffect(() => {
        const _alleleFrequencyValues: Record<string, number> = {}
        renderedPopulations.map(populationFrequencie => {
            return populationFrequencie?.populations?.map(pop => {
                const alleleFrequencyAll = 1
                const alleleFrequencyXX = 2
                const alleleFrequencyXY = 3
                _alleleFrequencyValues[alleleFrequencyAll.toPrecision(4)] =
                    countChartSize(alleleFrequencyAll)
                _alleleFrequencyValues[alleleFrequencyXX.toPrecision(4)] =
                    countChartSize(alleleFrequencyXX)
                _alleleFrequencyValues[alleleFrequencyXY.toPrecision(4)] =
                    countChartSize(alleleFrequencyXY)
            })
        })
        _alleleFrequencyValues[totalAlleleFrequency.toPrecision(4)] =
            countChartSize(totalAlleleFrequency)

        setAlleleFrequencyValues(_alleleFrequencyValues)
    }, [])

    const renderPopulationRowHeader = (
        name: string,
        index: string,
        hideIcon: boolean
    ) => {
        return (
            <TableCell
                onClick={
                    hideIcon
                        ? undefined
                        : () => renderPopulationRowHeaderHandleClick(index)
                }
                colSpan={2}
            >
                <TableSortLabel
                    active={expandedPopulations[index]}
                    hideSortIcon={hideIcon}
                    direction='desc'
                >
                    {GNOMAD_POPULATION_NAMES[
                        name as keyof typeof GNOMAD_POPULATION_NAMES
                    ]
                        ? GNOMAD_POPULATION_NAMES[
                            name as keyof typeof GNOMAD_POPULATION_NAMES
                        ]
                        : name}
                </TableSortLabel>
            </TableCell>
        )
    }

    const renderPopulationRowHeaderHandleClick = (name: string) => {
        setExpandedPopulations(prevState => {
            return {
                ...prevState,
                [name]: !prevState[name],
            }
        })
    }

    const getDisplayForChild = (name: string) => {
        return expandedPopulations[name] ? 'table-row' : 'none'
    }

    return (
        <TableContainer>
            <Table sx={{tableLayout: 'fixed', overflow: 'hidden'}}>
                <TableHead>
                    <TableRow>
                        <TableHeadCell colSpan={2}>Population</TableHeadCell>
                        <TableHeadCell>Allele Count</TableHeadCell>
                        <TableHeadCell>Allele Number</TableHeadCell>
                        <TableHeadCell>Allele Frequency</TableHeadCell>
                        <VariantPagePopulationFrequenciesHeadCellBar
                            containerWidth={575}
                        />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {population_frequencies?.filter(freq => !!freq && !!freq.population_source && [PopulationSourceType.FrequenciesGenomeAlleleStats, PopulationSourceType.FrequenciesGnomad].includes(freq.population_source))
                        .sort((a) => {
                            if (a!.population_source === PopulationSourceType.FrequenciesGenomeAlleleStats) {
                                return -1
                            } else if (a!.population_source === PopulationSourceType.FrequenciesGnomad) {
                                return 1
                            }
                            return 0
                        }).map(freq => <>
                            <TableRow><TablePopulationCellSubHeader colSpan={12}>{freq?.population_source && (POP_FREQ_ROW_NAMES[freq.population_source.valueOf()] || freq.population_source)}</TablePopulationCellSubHeader></TableRow>
                            {freq?.populations?.map((pop, idx) =>
                                <TableRow key={freq.population_source + '_' + pop?.population_subtype + '_' + idx}>
                                    <IndentedTableCell colSpan={2}>{pop?.population_subtype}</IndentedTableCell>
                                    <TableCell>{pop?.allele_stats?.allele_count}</TableCell>
                                    <TableCell>{pop?.allele_stats?.allele_number}</TableCell>
                                    <TableCell>
                                        {dashIfUndefined((pop?.allele_stats?.allele_frequency) ? pop.allele_stats?.allele_frequency.toPrecision(4) : undefined)}
                                    </TableCell>
                                    <VariantPagePopulationFrequenciesCellBar
                                        size={(pop?.allele_stats?.allele_frequency) ? countChartSize(Number(pop.allele_stats?.allele_frequency.toPrecision(4))) : 0}
                                    />
                                </TableRow>
                            )}
                        </>
                        )}
                </TableBody>

                {/*renderedPopulations.map((population_frequencie, i) =>
                    population_frequencie?.populations?.map((pop, index) => (
                        <TableBody key={index + i}>
                            <TableRow>
                                {renderPopulationRowHeader(
                                    pop.population_subtype
                                        ? pop.population_subtype
                                        : (index + i).toString(),
                                    index.toString(),
                                    !(
                                        pop.allele_count !== undefined &&
                                        pop.allele_number !== undefined &&
                                        pop.allele_count !== undefined &&
                                        pop.allele_number !== undefined
                                    )
                                )}
                                <TableCell>{pop.allele_count}</TableCell>
                                <TableCell>{pop.allele_number}</TableCell>
                                <TableCell style={{ paddingLeft: '25px' }}>
                                    {dashIfUndefined((pop.allele_frequency) ? pop.allele_frequency.toPrecision(4): undefined)}
                                </TableCell>
                                <VariantPagePopulationFrequenciesCellBar
                                    size={
                                        alleleFrequencyValues[
                                            dashIfUndefined((pop.allele_frequency) ? pop.allele_frequency.toPrecision(4): undefined)
                                        ]
                                    }
                                />
                            </TableRow>
                            <TableRow
                                sx={{
                                    display: getDisplayForChild(
                                        (index + i).toString()
                                    ),
                                }}
                            >
                                <TableCell
                                    colSpan={2}
                                    sx={{ textAlign: 'right' }}
                                >
                                    Female
                                </TableCell>
                                <TableCell className='right-align'>
                                    {pop.allele_count}
                                </TableCell>
                                <TableCell className='right-align'>
                                    {pop.allele_number}
                                </TableCell>
                                <TableCell style={{ paddingLeft: '25px' }}>
                                    {dashIfUndefined((pop.allele_frequency) ? pop.allele_frequency.toPrecision(4): undefined)}
                                </TableCell>
                                <VariantPagePopulationFrequenciesCellBar
                                    size={
                                        alleleFrequencyValues[
                                            dashIfUndefined((pop.allele_frequency) ? pop.allele_frequency.toPrecision(4): undefined)
                                        ]
                                    }
                                />
                            </TableRow>
                            <TableRow
                                sx={{
                                    display: getDisplayForChild(
                                        (index + i).toString()
                                    ),
                                }}
                            >
                                <TableCell
                                    colSpan={2}
                                    sx={{ textAlign: 'right' }}
                                >
                                    Male
                                </TableCell>
                                <TableCell className='right-align'>
                                    {pop.allele_count}
                                </TableCell>
                                <TableCell className='right-align'>
                                    {pop.allele_number}
                                </TableCell>
                                <TableCell style={{ paddingLeft: '25px' }}>
                                    {dashIfUndefined((pop.allele_frequency) ? pop.allele_frequency.toPrecision(4): undefined)}
                                </TableCell>
                                <VariantPagePopulationFrequenciesCellBar
                                    size={
                                        alleleFrequencyValues[
                                            dashIfUndefined((pop.allele_frequency) ? pop.allele_frequency.toPrecision(4): undefined)
                                        ]
                                    }
                                />
                            </TableRow>
                        </TableBody>
                    ))
                )*/}
                <TableFooter>
                    <TableRow>
                        <TableCell colSpan={2}></TableCell>
                        <TableCell className='right-align'>
                        </TableCell>
                        <TableCell className='right-align'>
                        </TableCell>
                        <TableCell>
                        </TableCell>
                        <VariantPagePopulationFrequenciesCellBar
                            ref={ref}
                            size={0
                            }
                        />
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>
    )
}
