import React from 'react'
import MuiButton from '@mui/material/Button'
import { ButtonProps } from '@mui/material/Button/Button'
import styled from 'styled-components'

type Props = ButtonProps & {
    icon: React.ReactNode
}

const Button = styled(MuiButton)`
  font-weight: 400;
  padding: 0 10px;
  height: 20px;

  &:not(:last-child) {
    margin-right: 10px;
  }


  & .MuiSvgIcon-root {
    font-size: 11px;
    margin-right: 5px;
  }


`

export default function TableToolbarButton({ children, icon, ...rest }: Props) {
    return (
        <Button variant='contained' sx={{ fontWeight: 400 }} {...rest}>
            {icon}
            {children}
        </Button>
    )
}
