import React, { useEffect, useMemo, useRef } from 'react'
import { TableOptions, useFilters, useSortBy, useTable } from 'react-table'
import {
    TableCell,
    TableHeadCell as CoreTableHeadCell,
    TableHeadRow,
    TableRow,
    TableSortLabel,
} from '@/components/detail/DetailTable/DetailTableComponents'
import { useDispatch, useSelector } from 'react-redux'
import { InitialStateType, PageType } from '@/store/reducers'
import { selectInactiveItems, selectActiveItems } from '@/store/selectors'
import TableToolbar from '@/components/detail/DetailTable/TableToolbar/TableToolbar'
import multiSelectFilter from '@/components/detail/DetailTable/filterTypes/multiSelectFilter'
import DetailBlock from '@/components/detail/DetailBlock'
import { FixedSizeList, ListOnScrollProps } from 'react-window'
import styled from 'styled-components'
import { SizeMe } from 'react-sizeme'
import rangeInRangeFilter from '@/components/detail/DetailTable/filterTypes/rangeInRangeFilter'
import { filterItemsValues } from '@/components/detail/DetailTable/TableToolbar/TableFilters/VEPFilter/filterItems'
import { SET_SCROLL_TO_ELEMENT } from '@/store/types'
import includesContainsValue from '@/components/detail/DetailTable/filterTypes/includesContainsValue'

type Props = TableOptions<Record<string, any>> & {
    pageType: PageType
    setVisibleColumns: (value: Array<number>) => void
    setData: (data: any) => void
}

const filterTypes = {
    multiSelect: multiSelectFilter,
    rangeInRange: rangeInRangeFilter,
    includesContainsValue: includesContainsValue,
}

const DefaultColumn = {
    Filter: false,
    Header: <></>,
}

const getColumns = (
    stateColumns: Array<{
        id: string
        active: boolean
    }>,
    columns: Props['columns']
) => {
    return stateColumns.map(stateColumn => {
        return {
            ...columns[Number(stateColumn.id)],
            active: stateColumn.active,
        }
    })
}

const TableHeadCell = styled(CoreTableHeadCell)`
  position: sticky;
  top: 0;
  background-color: ${props => props.theme.palette.white.main};
`

const DetailBlockWrapper = styled(DetailBlock)`
  margin-top: 8px;
  padding: 0;
`

const DetailTableWrapper = styled.div`
  overflow-x: auto;
  padding-bottom: 30px;
`

const DetailTable = ({
    columns,
    data,
    pageType,
    setVisibleColumns,
    setData,
}: Props) => {
    const listRef = useRef<any>(null)
    const dispatch = useDispatch()

    const stateColumns = useSelector(
        (state: InitialStateType) => state.detail.columns[pageType]
    )

    const scrollToElement = useSelector(
        (state: InitialStateType) => state.detail.scrollToElement
    )

    const typeColumns = useMemo(() => {
        return getColumns(stateColumns, columns)
    }, [stateColumns])

    const activeStateColumns = useSelector((state: InitialStateType) =>
        selectActiveItems(state, pageType)
    )

    const inactiveColumnsIds = useSelector((state: InitialStateType) =>
        selectInactiveItems(state, pageType)
    ).map(col => col.id)

    const activeColumns = useMemo(() => {
        return getColumns(activeStateColumns, columns)
    }, [activeStateColumns])

    const tableInstance = useTable(
        {
            columns: typeColumns,
            data,
            defaultColumn: DefaultColumn,
            filterTypes,
            initialState: {
                hiddenColumns: [
                    ...typeColumns
                        .filter(column => column.show === false)
                        .map(column => (column.id ? column.id : '')),
                    ...inactiveColumnsIds,
                ],
                filters: [
                    {
                        id: '9',
                        value: filterItemsValues,
                    },
                ],
            },
        },
        useFilters,
        useSortBy
    )

    const { headerGroups, rows, prepareRow, state } = tableInstance

    const RenderRow = React.useCallback(
        ({ index, style, data: { columnWidths } }) => {
            const row = rows[index]
            prepareRow(row)
            const { key: rowKey, ...getRowProps } = row.getRowProps()
            return (
                <TableRow
                    {...row.getRowProps({
                        style,
                    })}
                >
                    {row.cells.map((cell, i) => {
                        return (
                            <TableCell
                                {...cell.getCellProps()}
                                width={columnWidths[i]}
                                key={i}
                            >
                                {cell.render('Cell')}
                            </TableCell>
                        )
                    })}
                </TableRow>
            )
        },
        [prepareRow, rows]
    )

    const handleOnScroll = (scroll: ListOnScrollProps) => {
        const visibleRowIndex = scroll.scrollOffset / 16
        const totalHeight = 400
        const numberOfItemsVisible = visibleRowIndex + totalHeight / 16
        setVisibleColumns([
            Math.ceil(visibleRowIndex),
            Math.ceil(numberOfItemsVisible),
        ])
    }

    useEffect(() => {
        setData(rows.map(row => row.original))
    }, [state.filters, state.sortBy])

    useEffect(() => {
        if (scrollToElement) {
            const index = data.findIndex(
                (variant, i) =>
                    data[i + 1] &&
                    scrollToElement >= variant.pos &&
                    scrollToElement <= data[i + 1].pos
            )
            listRef?.current?.scrollToItem(index)
            dispatch(SET_SCROLL_TO_ELEMENT(null))
        }
    }, [scrollToElement])

    return (
        <>
            <TableToolbar
                pageType={pageType}
                activeColumns={activeColumns}
                stateColumns={typeColumns}
                data={data}
                visibleData={rows}
                instance={tableInstance}
            />
            <DetailBlockWrapper>
                <DetailTableWrapper>
                    <SizeMe>
                        {({ size }) => {
                            const columns: Array<any> = headerGroups.length
                                ? headerGroups[0].headers
                                : []

                            const availableWidth = size.width ? size.width : 0
                            const minGridWidth = columns.reduce((sum, col) => {
                                return sum + col.minWidth
                            }, 0)

                            const remainingWidth = Math.max(
                                availableWidth - minGridWidth
                                    ? minGridWidth
                                    : 0,
                                0
                            )

                            const totalGrowFactors =
                                columns.reduce(
                                    (sum, col) => sum + col.grow,
                                    0
                                ) || 1
                            const gridWidth = Math.max(
                                availableWidth,
                                minGridWidth
                            )

                            const columnWidths = columns.map(
                                col =>
                                    col.minWidth +
                                    (col.grow / totalGrowFactors) *
                                        remainingWidth
                            )

                            return (
                                <>
                                    {headerGroups.map(headerGroup => {
                                        const {
                                            key: headerGroupKey,
                                            ...getHeaderGroupProps
                                        } = headerGroup.getHeaderGroupProps()
                                        return (
                                            <TableHeadRow
                                                key={headerGroupKey}
                                                {...getHeaderGroupProps}
                                                style={{ width: gridWidth }}
                                            >
                                                {headerGroup.headers.map(
                                                    (column, i) => {
                                                        const {
                                                            key: headerKey,
                                                            ...getHeaderProps
                                                        } =
                                                            column.getHeaderProps()
                                                        const {
                                                            ...columnSortByProps
                                                        } =
                                                            column.getSortByToggleProps()
                                                        return (
                                                            <TableHeadCell
                                                                key={headerKey}
                                                                {...getHeaderProps}
                                                                width={
                                                                    columnWidths[
                                                                        i
                                                                    ]
                                                                }
                                                            >
                                                                <TableSortLabel
                                                                    active={
                                                                        column.isSorted
                                                                    }
                                                                    direction={
                                                                        column.isSortedDesc
                                                                            ? 'desc'
                                                                            : 'asc'
                                                                    }
                                                                    {...columnSortByProps}
                                                                >
                                                                    {column.render(
                                                                        'Header'
                                                                    )}
                                                                </TableSortLabel>
                                                            </TableHeadCell>
                                                        )
                                                    }
                                                )}
                                            </TableHeadRow>
                                        )
                                    })}
                                    <FixedSizeList
                                        ref={listRef}
                                        itemSize={16}
                                        height={rows.length > 0 ? 400 : 0}
                                        itemData={{
                                            columnWidths,
                                        }}
                                        itemCount={rows.length}
                                        width={gridWidth}
                                        onScroll={handleOnScroll}
                                    >
                                        {RenderRow}
                                    </FixedSizeList>
                                </>
                            )
                        }}
                    </SizeMe>
                </DetailTableWrapper>
            </DetailBlockWrapper>
        </>
    )
}

export default React.memo(DetailTable)
