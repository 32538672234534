import React, { useMemo, useState } from 'react'
import { Region } from '@/generated/graphql'
import DetailBlock from '@/components/detail/DetailBlock'
import DocumentTitle from '@/components/common/DocumentTitle'
import DetailHeading from '@/components/detail/DetailHeading'
import RegionPageTrack from '@/components/pages/RegionPage/RegionPageTrack'
import RegionPageTable from '@/components/pages/RegionPage/RegionPageTable'
import DetailSubheadingItem from '@/components/detail/DetailSubheadingItem'
import DetailSubheading from '@/components/detail/DetailSubheading'
import DetailLink from '@/components/detail/DetailLink'
import throttle from 'lodash.throttle'

type Props = {
    region: Region
}

export default function RegionPage({ region }: Props) {
    const regionTitle = `${region.chrom}-${region.start}-${region.stop}`
    const [visibleVariants, setVisibleVariants] = useState([0, 24])
    const [tableData, setTableData] = useState<any>([])

    const setVisibleColumns = useMemo(
        () =>
            throttle(visibleArray => {
                setVisibleVariants(visibleArray)
            }, 100),
        []
    )

    const hasGeneSymbols =
        (region.gene_symbols?.length ? region.gene_symbols.length : 0) > 0

    return (
        <>
            <DetailBlock>
                <DocumentTitle title={regionTitle} />
                <DetailHeading title={regionTitle} />
                <DetailSubheading>
                    <DetailSubheadingItem
                        title='Gene:'
                        condition={hasGeneSymbols}
                    >
                        {region.gene_symbols?.map((symbol, i) => {
                            const geneId = region.gene_ids
                                ? region.gene_ids[i]
                                : ''
                            if (geneId && symbol) {
                                return (
                                    <DetailLink to={`/gene/${geneId}`}>
                                        {symbol}
                                    </DetailLink>
                                )
                            }
                        })}
                    </DetailSubheadingItem>
                </DetailSubheading>
            </DetailBlock>
            <RegionPageTrack
                region={region}
                visibleVariant={visibleVariants}
                tableData={tableData}
            />
            <RegionPageTable
                region={region}
                setVisibleColumns={setVisibleColumns}
                setData={setTableData}
            />
        </>
    )
}
