import React from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import Button from '@mui/material/Button'
import {
    TOGGLE_ALL_ITEMS_INVISIBLE,
    TOGGLE_ALL_ITEMS_VISIBLE,
} from '@/store/types'
import { PageType } from '@/store/reducers'

const TableHideColumnDrawerToggleWrapper = styled.div`
  display: flex;
  padding: 16px 0 10px 16px;
`

const ToggleButton = styled(Button)`
  background-color: white;
  margin-left: 10px;
  border: 1px solid ${props => props.theme.palette.primary.main};
  height: 20px;
  min-width: 42px;
  font-size: 10px;
`

type Props = {
    pageType: PageType
}

export default function TableHideColumnDrawerToggle({ pageType }: Props) {
    const dispatch = useDispatch()

    const onToggleAllVisible = () => {
        dispatch(TOGGLE_ALL_ITEMS_VISIBLE(pageType))
    }

    const onToggleAllInvisible = () => {
        dispatch(TOGGLE_ALL_ITEMS_INVISIBLE(pageType))
    }

    return (
        <TableHideColumnDrawerToggleWrapper>
            <ToggleButton onClick={onToggleAllVisible}>Select all</ToggleButton>
            <ToggleButton onClick={onToggleAllInvisible}>
                Select none
            </ToggleButton>
        </TableHideColumnDrawerToggleWrapper>
    )
}
