import React from 'react'
import DetailHeading from '../../detail/DetailHeading'
import { VariantDetails } from '../../../generated/graphql'
import Grid from '@mui/material/Grid'
import DetailBlock from '@/components/detail/DetailBlock'
import VariantPageReferenceList from '@/components/pages/VariantPage/VariantPageReferenceList'
import VariantPageEffectPredictor from '@/components/pages/VariantPage/VariantPageEffectPredictor'
import VariantPagePopulationFrequencies from '@/components/pages/VariantPage/VariantPagePopulationFrequencies/VariantPagePopulationFrequencies'
import VariantPageOccurrence from '@/components/pages/VariantPage/VariantPageOccurrence'
import styled from 'styled-components'
import Box from '@mui/material/Box'
import DocumentTitle from '@/components/common/DocumentTitle'
import DetailSubheadingItem, {
    DetailSubheadingItemWrapper,
} from '@/components/detail/DetailSubheadingItem'
import DetailLink from '@/components/detail/DetailLink'
import { useSearchParams } from 'react-router-dom'
import Link from '@/components/common/Link'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'

type Props = {
    variant: VariantDetails
}

const ArrowBackIcon = styled(ArrowBackIosIcon)`
  font-size: 10px;
`

const VariantDetailHeading = styled(DetailHeading)`
  margin-bottom: 24px;
`

const VariantPageOccurrencesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const VariantPageWrapper = styled(DetailBlock)`
  & {
    padding-left: 53px;
    padding-right: 63px;
  }

  /*
  & .MuiTableCell-root, .MuiTypography-root {
    font-size: 10px;
  }*/
`

const GridWrapper = styled(Grid)`
  & .MuiTableCell-root, .MuiTypography-root {
    font-size: 10px;
  }
`

const GeneSubheading = styled.div`
  margin-bottom: 20px;

  ${DetailSubheadingItemWrapper} {
    display: block;
  }
`

const GenesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-height: 105px;
`

export default function VariantPage({ variant }: Props) {
    let variantTitle = 'Variant'
    if (variant.ref.length === 1 && variant.alt.length === 1) {
        variantTitle = 'Single nucleotide variant'
    }
    if (variant.ref.length < variant.alt.length) {
        const insertionLength = variant.alt.length - variant.ref.length
        variantTitle = `Insertion (${insertionLength} base${
            insertionLength > 1 ? 's' : ''
        })`
    }
    if (variant.ref.length > variant.alt.length) {
        const deletionLength = variant.ref.length - variant.alt.length
        variantTitle = `Deletion (${deletionLength} base${
            deletionLength > 1 ? 's' : ''
        })`
    }

    const [searchParams] = useSearchParams()
    const backButtonUrl = searchParams.get('back')

    return (
        <VariantPageWrapper>
            <DocumentTitle title={variant.variant_id} />
            {backButtonUrl && (
                <Link to={backButtonUrl}>
                    <ArrowBackIcon />
                    Back
                </Link>
            )}
            <VariantDetailHeading
                title={`${variantTitle}: ${variant.variant_id}`}
            />
            <GridWrapper container>
                <Grid item md={6} xs={12}>
                    <VariantPageOccurrencesWrapper>
                        <Box flexGrow={0}>
                            <VariantPageOccurrence variant={variant} />
                        </Box>
                        <Box flexGrow={0} marginLeft='auto'>
                            <GeneSubheading>
                                <DetailSubheadingItem
                                    title='Genes:'
                                    condition={variant.gene_ids?.some(
                                        gene => gene
                                    )}
                                >
                                    <GenesWrapper>
                                        {variant.gene_ids?.map(
                                            gene =>
                                                gene && (
                                                    <DetailLink
                                                        key={gene}
                                                        to={`/gene/${gene}`}
                                                    >
                                                        {gene}
                                                    </DetailLink>
                                                )
                                        )}
                                    </GenesWrapper>
                                </DetailSubheadingItem>
                            </GeneSubheading>
                            <VariantPageReferenceList variant={variant} />
                        </Box>
                    </VariantPageOccurrencesWrapper>
                </Grid>
                <Grid item md={1} xs={12} />
                <Grid item md={5} xs={12}>
                    <VariantPageEffectPredictor variant={variant} />
                </Grid>

                <Grid item xs={12}>
                    <VariantPagePopulationFrequencies variant={variant} />
                </Grid>
            </GridWrapper>
        </VariantPageWrapper>
    )
}
