import React from 'react'
import BaseQuery from '../../BaseQuery'
import { BaseQueryChildrenType } from '../../types/BaseQueryChildrenType'
import { gql } from '@apollo/client'
import { useParams } from 'react-router-dom'
import VariantPage from './VariantPage'

const query = gql`
    query VariantDetail($variantId: String!) {
        variant(variant_id: $variantId) {
            variant_id
            pos
            ref
            alt
            length
            population_frequencies {
                population_source
                populations {
                    population_subtype
                    variant_id
                    allele_stats {
                        allele_count
                        allele_number
                        allele_frequency
                    }
                }
                    }
            
            coverage_exome {
                mean
                pos
            }
            coverage_genome {
                mean
                pos
            }
            rsids
            transcript_consequence {
                transcript_id
                consequence
                hgvsc
            }
            
        }
    }
`

type Params = {
    variantId: string
}

export default function VariantPageContainer() {
    const { variantId = '' } = useParams<Params>()

    return (
        <BaseQuery
            query={query}
            variables={{
                variantId,
            }}
            loadingMessage='Loading variant'
            notFoundMessage='Variant not found'
        >
            {({ data }: BaseQueryChildrenType) => {
                return <VariantPage variant={data.variant} />
            }}
        </BaseQuery>
    )
}
