import { useLocation } from 'react-router-dom'
import styled, { css } from 'styled-components'
import React from 'react'
import CleanLink from '@/components/common/CleanLink'
import { ArrowDropUp } from '@mui/icons-material'

const MenuItemWrapper = styled.div<{
    isActive: boolean
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100%;
  padding: 0 20px;
  font-size: 20px;
  font-weight: 700;
  color: ${props => props.theme.palette.text.secondary};

  ${props =>
        props.isActive &&
      css`
        &:after {
          background-color: #ddeceb;
        }
      `}
`

const MenuItemIconWrapper = styled.span<{
    isActive: boolean
}>`
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: -10px;
  visibility: hidden;
  ${props =>
        props.isActive &&
      css`
        visibility: visible;
      `}
`

type Props = {
    to: string
    title: string
}

export default function MenuItem(props: Props) {
    const location = useLocation()
    const isActive = location.pathname === props.to

    return (
        <MenuItemWrapper isActive={isActive}>
            <CleanLink to={props.to}>{props.title}</CleanLink>
            <MenuItemIconWrapper isActive={isActive}>
                <ArrowDropUp />
            </MenuItemIconWrapper>
        </MenuItemWrapper>
    )
}
