import { FilterValue, Row } from 'react-table'

export default function rangeInRangeFilter<T extends Record<string, unknown>>(
    rows: Array<Row<T>>,
    columnIds: any,
    filterValue: FilterValue
) {
    return rows.filter(
        row =>
            row.values[columnIds[0]][0] >= filterValue[0] &&
            row.values[columnIds[0]][1] <= filterValue[1]
    )
}
