import React, { PropsWithChildren } from 'react'
import styled from 'styled-components'
import { PropsWithClassName } from '@/utils/styled'

const DetailBlockWrapper = styled.div`
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.16);
  border-radius: 5px;
  background-color: ${props => props.theme.palette.white.main};
  padding: 15px 10px;
  margin-top: 20px;
  margin-bottom: 22px;
`

export default function DetailBlock({
    children,
    className,
}: PropsWithChildren<PropsWithClassName<Record<string, unknown>>>) {
    return (
        <DetailBlockWrapper className={className}>
            {children}
        </DetailBlockWrapper>
    )
}
