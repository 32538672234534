import React, { useMemo, useState } from 'react'
import DetailHeading from '../../detail/DetailHeading'
import { Transcript } from '@/generated/graphql'
import DetailBlock from '@/components/detail/DetailBlock'
import TranscriptPageTable from '@/components/pages/TranscriptPage/TranscriptPageTable'
import DetailSubheading from '@/components/detail/DetailSubheading'
import DetailSubheadingItem from '@/components/detail/DetailSubheadingItem'
import Link from '@/components/common/Link'
import DocumentTitle from '@/components/common/DocumentTitle'
import TranscriptPageTrack from '@/components/pages/TranscriptPage/TranscriptPageTrack'
import throttle from 'lodash.throttle'

type Props = {
    transcript: Transcript
}

export default function TranscriptPage({ transcript }: Props) {
    const regionId = `${transcript.chrom}-${transcript.start}-${transcript.stop}`
    const [visibleVariants, setVisibleVariants] = useState([0, 24])
    const [tableData, setTableData] = useState<any>([])

    const setVisibleColumns = useMemo(
        () =>
            throttle(visibleArray => {
                setVisibleVariants(visibleArray)
            }, 100),
        []
    )

    return (
        <>
            <DetailBlock>
                <DocumentTitle title={transcript.transcript_id} />
                <DetailHeading
                    title={`Transcript: ${transcript.transcript_id}`}
                />
                <DetailSubheading>
                    <DetailSubheadingItem
                        title='Gene:'
                        condition={transcript.gene_symbol || transcript.gene_id}
                    >
                        <Link to={`/gene/${transcript.gene_id}`}>
                            {transcript.gene_symbol
                                ? transcript.gene_symbol
                                : transcript.gene_id}
                        </Link>
                    </DetailSubheadingItem>
                    <DetailSubheadingItem
                        title={'Canonical transcript:'}
                        condition={false}
                    >
                        <></>
                    </DetailSubheadingItem>
                    <DetailSubheadingItem title='Region:' condition={regionId}>
                        <Link to={`/region/${regionId}`}>{regionId}</Link>
                    </DetailSubheadingItem>
                </DetailSubheading>
            </DetailBlock>
            <TranscriptPageTrack
                tableData={tableData}
                transcript={transcript}
                visibleVariant={visibleVariants}
            />
            <TranscriptPageTable
                transcript={transcript}
                setVisibleColumns={setVisibleColumns}
                setData={setTableData}
            />
        </>
    )
}
