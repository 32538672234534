import React from 'react'
import VariantTrack from '@/components/detail/DetailTrack/VariantTrack/VariantTrack'
import RegionViewer from '@/components/detail/RegionViewer/RegionViewer'
import { useWindowSize } from '@/utils/windowSize'
import PositionAxisTrack from '@/components/detail/DetailTrack/PositionAxisTrack/PositionAxisTrack'
import { Coverage, Maybe } from '@/generated/graphql'

import styled from 'styled-components'
import DetailBlock from '@/components/detail/DetailBlock'
import Cursor from '@/components/detail/RegionViewer/Cursor'
import { useDispatch } from 'react-redux'
import { SET_SCROLL_TO_ELEMENT } from '@/store/types'

type Props = {
    start: number
    stop: number
    variants: any
    variantsInTable: any
    genomeCoverage: Maybe<Array<Maybe<Coverage>>> | undefined
    exomeCoverage: Maybe<Array<Maybe<Coverage>>> | undefined
    visibleVariant: Array<number>
}

const DetailTrackBlock = styled(DetailBlock)`
  padding: 10px 15px;
`

export default function DetailTrack({
    start,
    stop,
    variants,
    variantsInTable,
    genomeCoverage,
    exomeCoverage,
    visibleVariant,
}: Props) {
    const { width: windowWidth } = useWindowSize()
    const dispatch = useDispatch()

    const leftPanelWidth = 20

    const getRegionWidth = () => {
        const containerPadding = 44 // 24 container padding + 20 wrapper padding
        const menuSideBarWidth = 40
        const maxMaxWidth = 1536

        const containerSize =
            windowWidth - menuSideBarWidth - containerPadding * 2
        if (containerSize < maxMaxWidth) {
            return containerSize
        }
        return maxMaxWidth - leftPanelWidth - containerPadding * 2
    }

    const regionViewerRegions = [
        {
            start: Math.max(1, start - 75),
            stop: stop + 75,
        },
    ]

    const onCursorClick = (position: number) => {
        dispatch(SET_SCROLL_TO_ELEMENT(position))
    }

    return (
        <DetailTrackBlock>
            <RegionViewer
                contextType='gene'
                leftPanelWidth={20}
                width={getRegionWidth()}
                regions={regionViewerRegions}
                rightPanelWidth={0}
                renderOverview={() => <></>}
                zoomDisabled={true}
            >
                {/*<CoverageTrack
                    coverageOverThresholds={[0, 50, 100]}
                    datasets={coverageConfigClassic(
                        exomeCoverage,
                        genomeCoverage
                    )}
                    filenameForExport={() => '_coverage'}
                    height={136}
                />*/}
                <Cursor onClick={onCursorClick}>
                    <VariantTrack title={'Variants:'} variants={variants} />
                    <VariantTrack
                        title='Variant in table:'
                        variants={variantsInTable.slice(
                            visibleVariant[0],
                            visibleVariant[1] + 1
                        )}
                    />
                    <PositionAxisTrack />
                </Cursor>
            </RegionViewer>
        </DetailTrackBlock>
    )
}
