import React from 'react'
import CardItem from './CardItem'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Link from '@/components/common/Link'
import { useSelector } from 'react-redux'
import DocumentTitle from '@/components/common/DocumentTitle'
import { InitialStateType } from '@/store/reducers'

export default function SearchPage() {
    const recentSearches = useSelector(
        (state: InitialStateType) => state.search.history.recentSearches
    )

    return (
        <>
            <DocumentTitle title={'Search'} />
            <Typography
                variant='body1'
                maxWidth='410px'
                marginX='auto'
                marginTop='25px'
                marginBottom='45px'
            >
                You can search the Czech genome database by several criteria:
                gene, region, transcript or variant ID. The search bar has a
                whisperer implemented so start writing the first letters and you
                will be suggested possible searches to choose from.
            </Typography>

            <Grid
                container
                spacing={{ xs: 12, md: 18 }}
                sx={{ justifyContent: 'center' }}
            >
                <Grid item xs={12} lg={4}>
                    <CardItem title='Examples'>
                        <Typography variant='body1'>
                            <strong>Gene:&nbsp;</strong>
                            <Link to='/gene/ENSG00000000457'>SCYL3</Link>
                        </Typography>
                        <Typography variant='body1'>
                            <strong>Region: </strong>
                            <Link to='/region/1-55039447-55044852'>
                                1-55039447-55044852
                            </Link>
                        </Typography>
                        <Typography variant='body1'>
                            <strong>Transcript:&nbsp;</strong>
                            <Link to='/transcript/ENST00000008440.9'>
                                ENST00000008440.9
                            </Link>
                        </Typography>
                        <Typography variant='body1'>
                            <strong>Variant id:&nbsp;</strong>
                            <Link to='/variant/1-1229307-G-C'>
                                1-1229307-G-C
                            </Link>
                        </Typography>
                    </CardItem>
                </Grid>
                {/*<Grid item xs={12} lg={3}>
                    <CardItem title='Recent searches'>
                        {recentSearches.map((recentSearch, index) => (
                            <div key={index}>
                                <Link to={recentSearch.url}>
                                    {recentSearch.label}
                                </Link>
                            </div>
                        ))}
                    </CardItem>
                </Grid>
                <Grid item xs={12} lg={3}>
                    <CardItem title='Popular searches'>
                        <div />
                    </CardItem>
                </Grid>*/}
            </Grid>
        </>
    )
}
