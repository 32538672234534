import React, { useEffect, useState } from 'react'
import { FilterProps } from 'react-table'
import styled from 'styled-components'
import TableFilterDropdown from '@/components/detail/DetailTable/TableToolbar/TableFilters/TableFilterDropdown'
import VEPFilterItem from '@/components/detail/DetailTable/TableToolbar/TableFilters/VEPFilter/VEPFilterItem'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import {
    filterItems,
    filterItemsValues,
} from '@/components/detail/DetailTable/TableToolbar/TableFilters/VEPFilter/filterItems'

const VEPFilterWrapper = styled.div`
  width: 800px;
  padding: 9px;

  p {
    font-weight: 700;
  }
`

const VEPFilterCategoryTitle = styled(Typography)`
  display: inline-flex;
  border: 1px solid currentColor;
  border-radius: 5px;
  margin-right: 23px;
  padding: 2px 3px;
  cursor: pointer;
`

const VEPFilterCategoriesWrapper = styled.div`
  display: flex;
`

const VEPFilterItemsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-top: 5px;
`

export type CategoryType = 'high' | 'moderate' | 'low' | 'modifier' | 'none'

export const getCategoryColor = (category: CategoryType) => {
    switch (category) {
    case 'high':
        return '#CC003E'
    case 'moderate':
        return '#FF9300'
    case 'low':
        return '#15B900'
    }
}

export default function VEPFilter({
    column: { setFilter, filterValue },
    preFilteredRows,
}: FilterProps<any>) {
    const [itemsValues, setItemsValues] =
        useState<Array<string | undefined | null>>(filterValue)
    const [anchorEl, setAnchorEl] = useState<Element | null>(null)

    const handleClose = () => {
        setAnchorEl(null)
        setItemsValues(filterValue)
    }

    const handleButtonClick = () => {
        setFilter(itemsValues)
        handleClose()
    }

    const handleCategoryClick = (name: keyof typeof filterItems) => {
        setItemsValues(filterItems[name])
    }

    const handleAllCategoryClick = () => {
        setItemsValues(filterItemsValues)
    }

    const handleNoneCategoryClick = () => {
        setItemsValues([undefined, null])
    }

    useEffect(() => {
        if (filterValue === undefined) {
            setItemsValues(filterItemsValues)
        }
    }, [filterValue])

    return (
        <TableFilterDropdown
            id='vep-filter'
            title='VEP consequence'
            anchorEl={anchorEl}
            onAnchorElChange={setAnchorEl}
            onClose={handleClose}
        >
            <VEPFilterWrapper>
                <VEPFilterCategoriesWrapper>
                    <VEPFilterCategoryTitle
                        variant='body1'
                        onClick={handleAllCategoryClick}
                    >
                        ALL
                    </VEPFilterCategoryTitle>
                    <VEPFilterCategoryTitle
                        variant='body1'
                        onClick={handleNoneCategoryClick}
                    >
                        NONE
                    </VEPFilterCategoryTitle>
                    {(
                        Object.keys(filterItems) as Array<
                            keyof typeof filterItems
                        >
                    ).map(key => (
                        <VEPFilterCategoryTitle
                            key={key}
                            variant='body1'
                            color={getCategoryColor(key)}
                            onClick={() => handleCategoryClick(key)}
                        >
                            {key.toUpperCase()} only
                        </VEPFilterCategoryTitle>
                    ))}
                </VEPFilterCategoriesWrapper>
                <VEPFilterItemsWrapper>
                    {(
                        Object.keys(filterItems) as Array<
                            keyof typeof filterItems
                        >
                    ).map(key =>
                        filterItems[key].map((el, index) => (
                            <VEPFilterItem
                                key={`{${key}_${index}`}
                                category={key}
                                title={el}
                                checked={
                                    itemsValues
                                        ? itemsValues.includes(el)
                                        : false
                                }
                                onCheckBoxChange={value => {
                                    if (value) {
                                        setItemsValues([
                                            ...(itemsValues ? itemsValues : []),
                                            el,
                                        ])
                                    } else {
                                        const newArray = [
                                            ...(itemsValues ? itemsValues : []),
                                        ]
                                        const index = newArray.indexOf(el)
                                        if (index !== -1) {
                                            newArray.splice(index, 1)
                                            setItemsValues(newArray)
                                        }
                                    }
                                }}
                            />
                        ))
                    )}
                </VEPFilterItemsWrapper>
            </VEPFilterWrapper>
            <Button
                onClick={handleButtonClick}
                variant='contained'
                fullWidth={true}
            >
                Apply
            </Button>
        </TableFilterDropdown>
    )
}
