import React from 'react'
import TableFilterCheckbox from '@/components/detail/DetailTable/TableToolbar/TableFilters/TableFilterCheckbox'

type Props = {
    checked: boolean
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export default function Genome({ checked, onChange }: Props) {
    return (
        <TableFilterCheckbox
            title='Genome'
            checked={checked}
            onChange={onChange}
        />
    )
}
