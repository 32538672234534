import React from 'react'
import { TableInstance } from 'react-table'
import Button from '@mui/material/Button'
import styled from 'styled-components'
import Typography from '@mui/material/Typography'
import FilterListIcon from '@mui/icons-material/FilterList'

const ClearButton = styled(Button)`
  background-color: white;
  margin-left: 10px;
  border: 1px solid ${props => props.theme.palette.primary.main};
  height: 20px;
  min-width: 42px
`

const TableFiltersWrapper = styled.div`
  display: flex;
  height: 20px;

  .MuiTypography-root {
    font-size: 10px;
  }
`

const TableFiltersWrapperInner = styled.div`
  display: flex;
  align-items: center;
  background-color: ${props => props.theme.palette.primary.main};
  color: ${props => props.theme.palette.white.main};
  border-radius: 5px;
  padding-left: 5px;
`

type Props = {
    instance: TableInstance
}

export default function TableFilters({ instance }: Props) {
    const { allColumns, setAllFilters } = instance

    const filtersOrder = [
        'Source',
        'VEP Annotation',
        'Allele Frequency',
        'Region',
    ]

    return (
        <TableFiltersWrapper>
            <TableFiltersWrapperInner>
                <FilterListIcon sx={{ fontSize: '11px', marginRight: '9px' }} />
                {filtersOrder.map(key => {
                    const column = allColumns.find(
                        column => column.Header === key
                    )
                    if (column) {
                        return <div key={key}>{column.render('Filter')}</div>
                    }
                    return <></>
                })}
            </TableFiltersWrapperInner>
            <ClearButton onClick={() => setAllFilters([])}>
                <Typography variant='body1' color='primary.main'>
                    Clear
                </Typography>
            </ClearButton>
        </TableFiltersWrapper>
    )
}
