import React from 'react'
import DetailLink from '@/components/detail/DetailLink'
import { VariantDetails } from '@/generated/graphql'
import Typography from '@mui/material/Typography'

type Props = {
    variant: VariantDetails
}

export default function VariantPageReferenceList({ variant }: Props) {
    return (
        <div>
            <Typography variant='body1' fontWeight='bold' color='black.main'>
                External resources:
            </Typography>
            {variant.rsids && (
                <DetailLink
                    to={`https://www.ncbi.nlm.nih.gov/snp/?term=${variant.rsids}`}
                    target='_blank'
                >
                    dbSNP ({variant.rsids})
                </DetailLink>
            )}
        </div>
    )
}
