import {
    ADD_RECENT_SEARCH_ITEM,
    REORDER_ITEM,
    SET_NAVIGATION,
    SET_SCROLL_TO_ELEMENT,
    TOGGLE_ALL_ITEMS_INVISIBLE,
    TOGGLE_ALL_ITEMS_VISIBLE,
    TOGGLE_ITEM_ACTIVE,
    TOGGLE_NAVIGATION,
    TOGGLE_SEARCH_ERROR,
} from './types'
import { createReducer } from '@reduxjs/toolkit'
import { arrayMoveImmutable } from 'array-move'

export type PageType = 'gene' | 'transcript' | 'region'

type DetailType = {
    columns: Record<
        PageType,
        Array<{
            id: string
            active: boolean
        }>
    >
    scrollToElement: number | null
}

type NavigationType = {
    opened: boolean
}

type SearchType = {
    history: {
        recentSearches: Array<{ label: string; url: string }>
    }
    error: boolean
}

export type InitialStateType = {
    navigation: NavigationType
    detail: DetailType
    search: SearchType
}

export const initialState: InitialStateType = {
    navigation: {
        opened: false,
    },
    detail: {
        scrollToElement: null,
        columns: {
            gene: [
                {
                    id: '0',
                    active: true,
                },
                {
                    id: '1',
                    active: true,
                },
                {
                    id: '2',
                    active: true,
                },
                {
                    id: '3',
                    active: true,
                },
                {
                    id: '4',
                    active: true,
                },
                {
                    id: '5',
                    active: true,
                },
                {
                    id: '6',
                    active: true,
                },
                {
                    id: '7',
                    active: true,
                },
                {
                    id: '8',
                    active: true,
                },
                {
                    id: '9',
                    active: true,
                },
                {
                    id: '10',
                    active: true,
                },
                {
                    id: '11',
                    active: false,
                },
                {
                    id: '12',
                    active: true,
                },
                {
                    id: '13',
                    active: true,
                },
                {
                    id: '14',
                    active: true,
                },
            ],
            transcript: [
                {
                    id: '0',
                    active: true,
                },
                {
                    id: '1',
                    active: true,
                },
                {
                    id: '2',
                    active: true,
                },
                {
                    id: '3',
                    active: true,
                },
                {
                    id: '4',
                    active: true,
                },
                {
                    id: '5',
                    active: true,
                },
                {
                    id: '6',
                    active: true,
                },
                {
                    id: '7',
                    active: true,
                },
                {
                    id: '8',
                    active: true,
                },
                {
                    id: '9',
                    active: true,
                },
                {
                    id: '10',
                    active: true,
                },
                {
                    id: '11',
                    active: false,
                },
                {
                    id: '12',
                    active: true,
                },
                {
                    id: '13',
                    active: true,
                },
                {
                    id: '14',
                    active: true,
                },
            ],
            region: [
                {
                    id: '0',
                    active: true,
                },
                {
                    id: '1',
                    active: true,
                },
                {
                    id: '2',
                    active: true,
                },
                {
                    id: '3',
                    active: true,
                },
                {
                    id: '4',
                    active: true,
                },
                {
                    id: '5',
                    active: true,
                },
                {
                    id: '6',
                    active: true,
                },
                {
                    id: '7',
                    active: true,
                },
                {
                    id: '8',
                    active: true,
                },
                {
                    id: '9',
                    active: true,
                },
                {
                    id: '10',
                    active: true,
                },
                {
                    id: '11',
                    active: false,
                },
                {
                    id: '12',
                    active: true,
                },
                {
                    id: '13',
                    active: true,
                },
                {
                    id: '14',
                    active: true,
                },
            ],
        },
    },
    search: {
        history: {
            recentSearches: [],
        },
        error: false,
    },
}

function applyToggleNavigation(state: InitialStateType): InitialStateType {
    return {
        ...state,
        navigation: {
            opened: !state.navigation.opened,
        },
    }
}

function applySetNavigation(
    state: InitialStateType,
    action: ReturnType<typeof SET_NAVIGATION>
): InitialStateType {
    return {
        ...state,
        navigation: {
            opened: action.payload.value,
        },
    }
}

function applyReorderItem(
    state: InitialStateType,
    action: ReturnType<typeof REORDER_ITEM>
): InitialStateType {
    const movedArray = arrayMoveImmutable(
        state.detail.columns[action.payload.pageType],
        action.payload.from,
        action.payload.to
    )

    return {
        ...state,
        detail: {
            ...state.detail,
            columns: {
                ...state.detail.columns,
                [action.payload.pageType]: movedArray,
            },
        },
    }
}

function applyToggleItemActive(
    state: InitialStateType,
    action: ReturnType<typeof TOGGLE_ITEM_ACTIVE>
): InitialStateType {
    return {
        ...state,
        detail: {
            ...state.detail,
            columns: {
                ...state.detail.columns,
                [action.payload.pageType]: state.detail.columns[
                    action.payload.pageType
                ].map(col => {
                    return col.id === action.payload.itemId
                        ? { ...col, active: !col.active }
                        : col
                }),
            },
        },
    }
}

function applyAddRecentSearchItem(
    state: InitialStateType,
    action: ReturnType<typeof ADD_RECENT_SEARCH_ITEM>
): InitialStateType {
    if (
        state.search.history.recentSearches.find(
            el => el.url === action.payload.item.url
        )
    ) {
        return { ...state }
    }
    const recentSearches = [...state.search.history.recentSearches]

    if (recentSearches.length > 9) {
        recentSearches.pop()
    }
    recentSearches.unshift(action.payload.item)

    return {
        ...state,
        search: {
            ...state.search,
            history: {
                recentSearches: recentSearches,
            },
        },
    }
}

function applyToggleSearchError(state: InitialStateType): InitialStateType {
    return {
        ...state,
        search: {
            ...state.search,
            error: !state.search.error,
        },
    }
}

function applyToggleAllItemsVisible(
    state: InitialStateType,
    action: ReturnType<typeof TOGGLE_ALL_ITEMS_VISIBLE>
): InitialStateType {
    return {
        ...state,
        detail: {
            ...state.detail,
            columns: {
                ...state.detail.columns,
                [action.payload.pageType]: state.detail.columns[
                    action.payload.pageType
                ].map(col => ({
                    ...col,
                    active: true,
                })),
            },
        },
    }
}

function applyToggleAllItemsInvisible(
    state: InitialStateType,
    action: ReturnType<typeof TOGGLE_ALL_ITEMS_INVISIBLE>
): InitialStateType {
    return {
        ...state,
        detail: {
            ...state.detail,
            columns: {
                ...state.detail.columns,
                [action.payload.pageType]: state.detail.columns[
                    action.payload.pageType
                ].map(col => ({
                    ...col,
                    active: false,
                })),
            },
        },
    }
}

function applySetScrollToElement(
    state: InitialStateType,
    action: ReturnType<typeof SET_SCROLL_TO_ELEMENT>
): InitialStateType {
    return {
        ...state,
        detail: {
            ...state.detail,
            scrollToElement: action.payload.elementIndex,
        },
    }
}

export default createReducer(initialState, builder => {
    builder
        .addCase(REORDER_ITEM, (state, action) => {
            return applyReorderItem(state, action)
        })
        .addCase(TOGGLE_NAVIGATION, state => {
            return applyToggleNavigation(state)
        })
        .addCase(SET_NAVIGATION, (state, action) => {
            return applySetNavigation(state, action)
        })
        .addCase(TOGGLE_ITEM_ACTIVE, (state, action) => {
            return applyToggleItemActive(state, action)
        })
        .addCase(ADD_RECENT_SEARCH_ITEM, (state, action) => {
            return applyAddRecentSearchItem(state, action)
        })
        .addCase(TOGGLE_SEARCH_ERROR, state => {
            return applyToggleSearchError(state)
        })
        .addCase(TOGGLE_ALL_ITEMS_VISIBLE, (state, action) => {
            return applyToggleAllItemsVisible(state, action)
        })
        .addCase(TOGGLE_ALL_ITEMS_INVISIBLE, (state, action) => {
            return applyToggleAllItemsInvisible(state, action)
        })
        .addCase(SET_SCROLL_TO_ELEMENT, (state, action) => {
            return applySetScrollToElement(state, action)
        })
        .addDefaultCase(() => {
            return initialState
        })
})
