import React from 'react'
import styled from 'styled-components'
import Typography from '@mui/material/Typography'
import Cross from '@/assets/icon/Cross'
import { alpha, ButtonBase } from '@mui/material'
import { PageType } from '@/store/reducers'
import TableHideColumnDrawerToggle from '@/components/detail/DetailTable/TableToolbar/TableHideColumnDrawer/TableHideColumnDrawerToggle'

const TableHideColumnDrawerHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  background: linear-gradient(${props =>
        props.theme.palette.primary.main} 0%, ${props =>
    alpha(props.theme.palette.primary.light, 0.5)} 100%);
  height: 72px;
  text-align: center;
  position: relative;
  padding: 0 80px;
  flex-shrink: 0;
`

const TableHideColumnDrawerHeaderIcon = styled(ButtonBase)`
  position: absolute;
  top: 12px;
  right: 20px;
`

type Props = {
    toggleDrawer: (value: boolean) => void
    pageType: PageType
}

export default function TableHideColumnDrawerHeader({
    toggleDrawer,
    pageType,
}: Props) {
    return (
        <>
            <TableHideColumnDrawerHeaderWrapper>
                <TableHideColumnDrawerHeaderIcon
                    onClick={() => toggleDrawer(false)}
                >
                    <Cross fill='#fff' />
                </TableHideColumnDrawerHeaderIcon>
                <Typography fontWeight='600' color='white.main'>
                    Select and reorder columns
                </Typography>
            </TableHideColumnDrawerHeaderWrapper>
            <TableHideColumnDrawerToggle pageType={pageType} />
        </>
    )
}
