import React from 'react'
import { FilterProps } from 'react-table'
import Exome from '@/components/detail/DetailTable/TableToolbar/TableFilters/SourceFilter/Exome/Exome'
import Genome from '@/components/detail/DetailTable/TableToolbar/TableFilters/SourceFilter/Genome/Genome'
import styled from 'styled-components'

const SourceFilterWrapper = styled.div`
  display: flex;
`

export default function SourceFilter({
    column: { filterValue, setFilter },
}: FilterProps<any>) {
    const getHandleChange = (type: string) => {
        return (event: React.ChangeEvent<HTMLInputElement>) => {
            // setFilter("genome")
            if (event.target.checked) {
                setFilter([...(filterValue ? filterValue : []), type])
            } else {
                const newArray = [...(filterValue ? filterValue : [])]
                const index = newArray.indexOf(type)
                if (index !== -1) {
                    newArray.splice(index, 1)
                    setFilter(newArray)
                }
            }
        }
    }

    return (
        <SourceFilterWrapper>
            <Exome
                checked={filterValue ? filterValue.includes('exome') : false}
                onChange={getHandleChange('exome')}
            />
            <Genome
                checked={filterValue ? filterValue.includes('genome') : false}
                onChange={getHandleChange('genome')}
            />
        </SourceFilterWrapper>
    )
}
