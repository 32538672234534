import React from 'react'
import BaseQuery from '../../BaseQuery'
import { BaseQueryChildrenType } from '../../types/BaseQueryChildrenType'
import { gql } from '@apollo/client'
import { useParams } from 'react-router-dom'
import TranscriptPage from './TranscriptPage'

const query = gql`
    query Transcript($transcriptId: String!) {
        transcript(transcript_id: $transcriptId) {
            transcript_id
            chrom
            start
            gene_id
            gene_symbol
            stop
            variants {
                chrom
                ref
                alt
                length
                transcript_id_canonical
                transcript_consequence_major {
                    gene_symbol
                    gene_id
                    consequence
                    transcript_id
                }
                source
                pos
                variant_id
                czechgenome_genome_frequencies_allAf
                czechgenome_genome_frequencies_allAc
                czechgenome_genome_frequencies_allAn
                czechgenome_genome_frequencies_allHc
                czechgenome_genome_frequencies_maleAf
                czechgenome_genome_frequencies_maleAc
                czechgenome_genome_frequencies_maleAn
                czechgenome_genome_frequencies_maleHc
                czechgenome_genome_frequencies_femaleAf
                czechgenome_genome_frequencies_femaleAc
                czechgenome_genome_frequencies_femaleAn
                czechgenome_genome_frequencies_femaleHc
                clinical_significance
                rsids
            }
        }
    }
`

type Params = {
    transcriptId: string
}

export default function TranscriptPageContainer() {
    const { transcriptId = '' } = useParams<Params>()

    return (
        <BaseQuery
            query={query}
            variables={{
                transcriptId,
            }}
            loadingMessage='Loading transcript'
            notFoundMessage='Transcript not found'
        >
            {({ data }: BaseQueryChildrenType) => {
                return <TranscriptPage transcript={data.transcript} />
            }}
        </BaseQuery>
    )
}
