import React from 'react'
import Typography from '@mui/material/Typography'

type Props = {
    title: string
    className?: string
}

export default function DetailHeading({ title, className }: Props) {
    return (
        <Typography variant='h1' color='black.main' className={className}>
            {title}
        </Typography>
    )
}
