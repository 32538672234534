import React, { useState, useMemo } from 'react'
import { Drawer } from '@mui/material'
import { PageType } from '@/store/reducers'
import TableHideColumnDrawerList from '@/components/detail/DetailTable/TableToolbar/TableHideColumnDrawer/TableHideColumnDrawerList'
import { DropResult } from 'react-beautiful-dnd'
import { useDispatch } from 'react-redux'
import TableHideColumnDrawerHeader from '@/components/detail/DetailTable/TableToolbar/TableHideColumnDrawer/TableHideColumnDrawerHeader'
import TableToolbarButton from '@/components/detail/DetailTable/TableToolbar/TableToolbarButton'
import ViewColumnIcon from '@mui/icons-material/ViewColumn'

type Props = {
    pageType: PageType
    columns: Array<any>
}

export default function TableHideColumnDrawer({ pageType, columns }: Props) {
    const [isOpened, setOpened] = useState(false)
    const dispatch = useDispatch()

    const toggleDrawer =
        (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
            if (
                event.type === 'keydown' &&
                ((event as React.KeyboardEvent).key === 'Tab' ||
                    (event as React.KeyboardEvent).key === 'Shift')
            ) {
                return
            }
            setOpened(open)
        }

    const onDragEnd = ({ destination, source }: DropResult) => {
        // dropped outside the list
        if (!destination) return
        dispatch({
            type: 'REORDER_ITEM',
            payload: {
                pageType: pageType,
                from: source.index,
                to: destination.index,
            },
        })
    }

    const items = useMemo(
        () =>
            columns.filter(
                col => col.show !== false && col.showInLayout !== false
            ),
        [columns]
    )

    return (
        <>
            <TableToolbarButton
                icon={<ViewColumnIcon />}
                onClick={() => setOpened(!isOpened)}
            >
                Table layout
            </TableToolbarButton>
            <Drawer
                anchor='right'
                open={isOpened}
                onClose={toggleDrawer(false)}
            >
                <TableHideColumnDrawerHeader
                    toggleDrawer={setOpened}
                    pageType={pageType}
                />
                <TableHideColumnDrawerList
                    items={items}
                    onDragEnd={onDragEnd}
                    pageType={pageType}
                />
            </Drawer>
        </>
    )
}
