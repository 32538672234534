import Typography from '@mui/material/Typography'
import React from 'react'

type Props = {
    message?: string
}

export default function NotFoundPageContent({
    message = 'Page not found',
}: Props) {
    return (
        <Typography variant='h1' textAlign='center' paddingTop='25px'>
            {message}
        </Typography>
    )
}
