import React, { PropsWithChildren } from 'react'

import { Cursor as BaseCursor } from '@gnomad/region-viewer'

type Props = {
    onClick: (position: number) => void
}

export default function Cursor(props: PropsWithChildren<Props>) {
    return (
        <BaseCursor
            {...props}
            renderCursor={(x: number) => (
                <line
                    x1={x}
                    y1={0}
                    x2={x}
                    y2='100%'
                    stroke='#000'
                    strokeWidth={1}
                />
            )}
        />
    )
}
