import React from 'react'
import { DocumentNode, useQuery } from '@apollo/client'
import { QueryHookOptions } from '@apollo/client/react/types/types'
import { BaseQueryChildrenType } from '@/components/types/BaseQueryChildrenType'
import NotFoundPageContent from '@/components/pages/NotFoundPage/NotFoundPageContent'

type Props = {
    children: (arg0: BaseQueryChildrenType) => JSX.Element
    query: DocumentNode
    loadingMessage?: string
    notFoundMessage?: string
    variables?: object
}

export default function BaseQuery({
    children,
    query,
    variables,
    notFoundMessage,
    loadingMessage = 'Loading',
}: Props) {
    const options: QueryHookOptions = {}
    if (variables) {
        options['variables'] = variables
    }
    const { loading, error, data } = useQuery(query, options)

    if (error) {
        // TODO: loading
        return <p>Error occurred</p>
    }

    if (loading) {
        // TODO: loading
        return <p>{loadingMessage}</p>
    }

    const isDataNull = Object.keys(data)
        .map(key => {
            return data[key]
        })
        .every(item => item)

    if (!isDataNull) {
        return <NotFoundPageContent message={notFoundMessage} />
    }

    return children({ error, data })
}
