import React from 'react'
import Tooltip from '@mui/material/Tooltip'

type Props = {
    length: number
    content: string
}

export default function ShortenedCell({ length, content }: Props) {
    return content.length > length ? (
        <Tooltip title={content}>
            <div>{content.substring(0, length) + '...'}</div>
        </Tooltip>
    ) : (
        <>{content}</>
    )
}
