import React, { useEffect } from 'react'
import TableFilterRange from '@/components/detail/DetailTable/TableToolbar/TableFilters/TableFilterRange'
import { FilterProps } from 'react-table'

export default function RegionFilter({
    column: { setFilter, preFilteredRows, id, filterValue },
}: FilterProps<any>) {
    const rangeRows = React.useMemo<Array<any>>(() => {
        const options = new Set()
        preFilteredRows.forEach(row => {
            options.add(row.values[id][0])
            options.add(row.values[id][1])
        })
        return [...options.values()]
    }, [id, preFilteredRows])
    const min = Math.min(...rangeRows)
    const max = Math.max(...rangeRows)

    const initialValue = [min, max]

    const [value, setValue] = React.useState<number | number[]>(initialValue)

    const formatRegionId = (value: number) =>
        value.toLocaleString('cs').replace(/ /g, ',')

    useEffect(() => {
        if (filterValue === undefined) {
            setValue([min, max])
        }
    }, [filterValue])

    const marks = [
        {
            value: min,
            label: formatRegionId(min),
        },
        {
            value: max,
            label: formatRegionId(max),
        },
    ]

    return (
        <TableFilterRange
            id='region-filter'
            title='Region'
            value={value}
            setValue={setValue}
            rangeArray={[min, max]}
            marks={marks}
            setFilter={setFilter}
            scaled={false}
            valueLabelFormat={formatRegionId}
            onClose={() => setValue(filterValue ? filterValue : initialValue)}
        ></TableFilterRange>
    )
}
