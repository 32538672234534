import React, { useMemo, useState } from 'react'
import { Gene } from '@/generated/graphql'
import DetailHeading from '../../detail/DetailHeading'
import Link from '@/components/common/Link'
import DetailBlock from '@/components/detail/DetailBlock'
import DetailSubheading from '@/components/detail/DetailSubheading'
import DetailSubheadingItem from '@/components/detail/DetailSubheadingItem'
import GenePageTable from '@/components/pages/GenePage/GenePageTable'
import GenePageTrack from '@/components/pages/GenePage/GenePageTrack'
import DocumentTitle from '@/components/common/DocumentTitle'

import throttle from 'lodash.throttle'
import GenePageTranscripts from '@/components/pages/GenePage/GenePageTranscripts'

type Props = {
    gene: Gene
}

export default function GenePage({ gene }: Props) {
    const regionId = `${gene.chrom}-${gene.start}-${gene.stop}`
    const geneSymbol = gene.gene_symbol ? gene.gene_symbol : gene.gene_id
    const [visibleVariants, setVisibleVariants] = useState([0, 24])
    const [tableData, setTableData] = useState<any>([])

    const setVisibleColumns = useMemo(
        () =>
            throttle(visibleArray => {
                setVisibleVariants(visibleArray)
            }, 100),
        []
    )

    return (
        <>
            <DetailBlock>
                <DocumentTitle title={geneSymbol} />
                <DetailHeading
                    title={`${geneSymbol}${gene.name ? `(${gene.name})` : ''}`}
                />
                <DetailSubheading>
                    <DetailSubheadingItem
                        title={'Canonical transcript:'}
                        condition={gene.canonical_transcript_id}
                    >
                        <Link
                            to={`/transcript/${gene.canonical_transcript_id}`}
                        >
                            {gene.canonical_transcript_id}
                        </Link>
                    </DetailSubheadingItem>
                    <DetailSubheadingItem title='Region:' condition={regionId}>
                        <Link to={`/region/${regionId}`}>{regionId}</Link>
                    </DetailSubheadingItem>
                    <DetailSubheadingItem
                        title='Other transcripts:'
                        condition={gene.transcript_ids?.some(gene => gene)}
                    >
                        <GenePageTranscripts transcripts={gene.transcript_ids} />
                    </DetailSubheadingItem>
                </DetailSubheading>
            </DetailBlock>
            <GenePageTrack
                gene={gene}
                tableData={tableData}
                visibleVariant={visibleVariants}
            />
            <GenePageTable
                gene={gene}
                setData={setTableData}
                setVisibleColumns={setVisibleColumns}
            />
        </>
    )
}
