import React from 'react'
import { BrowserRouter, Route, Routes as RouterRoutes } from 'react-router-dom'
import HomePage from './components/pages/HomePage/HomePage'
import Layout from './components/Layout'
import SearchPage from './components/pages/SearchPage/SearchPage'
import GenePageContainer from './components/pages/GenePage/GenePageContainer'
import InfoPage from './components/pages/InfoPage'
import RegionPageContainer from './components/pages/RegionPage/RegionPageContainer'
import TranscriptPageContainer from './components/pages/TranscriptPage/TranscriptPageContainer'
import VariantPageContainer from './components/pages/VariantPage/VariantPageContainer'
import NotFoundPage from '@/components/pages/NotFoundPage/NotFoundPage'

export default function Routes() {
    return (
        <BrowserRouter>
            <RouterRoutes>
                <Route path='/' element={<Layout />}>
                    <Route index element={<HomePage />} />
                    <Route path='/data-info' element={<InfoPage />} />
                    <Route path='/search' element={<SearchPage />} />
                    <Route path='*' element={<NotFoundPage />} />
                    <Route path='/gene/:gene' element={<GenePageContainer />} />
                    <Route
                        path='/region/:regionId'
                        element={<RegionPageContainer />}
                    />
                    <Route
                        path='/transcript/:transcriptId'
                        element={<TranscriptPageContainer />}
                    />
                    <Route
                        path='/variant/:variantId'
                        element={<VariantPageContainer />}
                    />
                </Route>
            </RouterRoutes>
        </BrowserRouter>
    )
}
