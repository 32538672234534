import React from 'react'
import MenuAppBar from './MenuAppBar'
import styled, { createGlobalStyle } from 'styled-components'
import { Outlet } from 'react-router-dom'
import Container from '@mui/material/Container'
import Footer from './Footer'

const LayoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`

const Main = styled.main`
  padding-left: 40px;
  position: relative;
  flex-grow: 1;
`

const GlobalStyle = createGlobalStyle`
  html, body {
    background-color: ${({ theme }: any) => theme.palette.primary['300']};
    font-family: 'IBM Plex Sans', sans-serif;
  }
`

export default function Layout() {
    return (
        <LayoutWrapper>
            <MenuAppBar />
            <Main>
                <Container maxWidth='xl'>
                    <Outlet />
                </Container>
            </Main>
            <GlobalStyle />
            <Footer />
        </LayoutWrapper>
    )
}
