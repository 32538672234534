import * as React from 'react'
import {
    DragDropContext,
    Droppable,
    OnDragEndResponder,
} from 'react-beautiful-dnd'
import TableHideColumnDrawerItem from '@/components/detail/DetailTable/TableToolbar/TableHideColumnDrawer/TableHideColumnDrawerItem'
import { PageType } from '@/store/reducers'

export type DraggableListProps = {
    items: Array<any>
    onDragEnd: OnDragEndResponder
    pageType: PageType
}

const TableHideColumnDrawerList = React.memo(
    ({ items, onDragEnd, pageType }: DraggableListProps) => {
        return (
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId='droppable-list'>
                    {provided => (
                        <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                        >
                            {items.map((item, index) => (
                                <TableHideColumnDrawerItem
                                    item={item}
                                    index={index}
                                    key={index}
                                    pageType={pageType}
                                />
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        )
    }
)

TableHideColumnDrawerList.displayName = 'TableHideColumnDrawerList'

export default TableHideColumnDrawerList
