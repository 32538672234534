import React, { PropsWithChildren } from 'react'
import styled from 'styled-components'
import MuiPopover from '@mui/material/Popover'
import MuiButtonBase from '@mui/material/ButtonBase'
import { css } from 'styled-components'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'

const TableFilterDropdownWrapper = styled.div`
  display: flex;
  align-items: center;

`

const Popover = styled(MuiPopover)`
  .MuiTypography-root {
    font-size: 10px;
  }
`

const ButtonBase = styled(MuiButtonBase)<{ $opened: boolean }>`
  text-transform: uppercase;
  font-size: 10px;
  height: 20px;
  border-radius: 5px;
  padding-left: 2px;

  & .MuiSvgIcon-root {
    font-size: 12px;
  }

  ${props =>
        props.$opened &&
      css`
            background-color: white;
            color: ${props => props.theme.palette.primary.main};;
          `}
`

type Props = {
    title: string
    id: string
    anchorEl: Element | null
    onClose?: () => void
    onAnchorElChange: (anchorEl: Element | null) => void
}

export default function TableFilterDropdown({
    id,
    title,
    anchorEl,
    children,
    onAnchorElChange,
    onClose,
}: PropsWithChildren<Props>) {
    const open = Boolean(anchorEl)
    const finalId = open ? id : undefined

    const handleButtonClick = (event: React.MouseEvent) => {
        onAnchorElChange(event.currentTarget)
    }

    return (
        <TableFilterDropdownWrapper>
            <ButtonBase $opened={open} onClick={handleButtonClick}>
                {title}
                <ArrowDropDownIcon />
            </ButtonBase>
            <Popover
                id={finalId}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                sx={{ fontSize: '10px' }}
                onClose={onClose}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                {children}
            </Popover>
        </TableFilterDropdownWrapper>
    )
}
