import { FilterValue, Row } from 'react-table'

export default function multiSelectFilter<T extends Record<string, unknown>>(
    rows: Array<Row<T>>,
    columnIds: any,
    filterValue: FilterValue
) {
    return filterValue.length === 0
        ? rows
        : rows.filter(row => filterValue.includes(String(row.original.source)))
}
