import * as React from 'react'

export default function Email() {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width={22.872} height={15.439}>
            <path
                d='M0 14.072V1.367q0-.022.066-.419l7.477 6.4-7.455 7.165A1.869 1.869 0 0 1 0 14.072ZM.993.066A.95.95 0 0 1 1.368 0h20.136a1.249 1.249 0 0 1 .4.066l-7.5 6.418-.993.794-1.963 1.61-1.963-1.61-.993-.794Zm.022 15.307 7.521-7.212 2.911 2.36 2.911-2.36 7.521 7.212a1.059 1.059 0 0 1-.375.066H1.367a1 1 0 0 1-.353-.066Zm14.336-8.028 7.455-6.4a1.316 1.316 0 0 1 .066.419v12.7a1.691 1.691 0 0 1-.066.441Z'
                fill='currentColor'
            />
        </svg>
    )
}
