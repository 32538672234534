import { createSelector } from '@reduxjs/toolkit'
import { InitialStateType, PageType } from '@/store/reducers'

export const selectActiveItems = createSelector(
    [
        (state: InitialStateType) => state.detail.columns,
        // Take the second arg, `category`, and forward to the output selector
        (state: InitialStateType, pageType: PageType) => pageType,
    ],
    (columns, pageType) => columns[pageType].filter(col => col.active)
)
export const selectInactiveItems = createSelector(
    [
        (state: InitialStateType) => state.detail.columns,
        // Take the second arg, `category`, and forward to the output selector
        (state: InitialStateType, pageType: PageType) => pageType,
    ],
    (columns, pageType) => columns[pageType].filter(col => !col.active)
)
