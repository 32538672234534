import React from 'react'
import styled from 'styled-components'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'

const FooterWrapper = styled.div`
  padding: 20px 0 12px 0;
  background-color: ${props => props.theme.palette.primary.main};
  z-index: 10;
  margin-top: auto;
  color: ${props => props.theme.palette.white.main};
  font-size: 10px;

  p {
    font-size: 10px;
  }

  a {
    color: ${props => props.theme.palette.white.main};
  }
`

const FooterInner = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
`

const LinksWrapper = styled.div`
  line-height: 2;
`

export default function Footer() {
    return (
        <FooterWrapper>
            <Container maxWidth='xl'>
                <FooterInner>
                    <div>
                        <Grid container>
                            <Grid item xs={3}>
                                <Typography textTransform='uppercase'>
                                    Contact us
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography>
                                    Institute of Applied Biotechnologies, a.s.
                                    Služeb 3056/4, 108 00 Praha-Strašnice
                                </Typography>
                                <Typography>
                                    Phone:
                                    <Link href='tel:+420255700885'>
                                        +420 255 700 885
                                    </Link>
                                </Typography>
                                <Typography>
                                    Phone:
                                    <Link href='tel:+420255700885'>
                                        +420 255 700 885
                                    </Link>
                                </Typography>
                                <Typography>
                                    E-mail:
                                    <Link href='mailto:iabio@iabio.eu'>
                                        iabio@iabio.eu
                                    </Link>
                                </Typography>
                            </Grid>
                        </Grid>
                    </div>
                    <div>
                        <LinksWrapper>
                            <Link href=''>Privacy Policy</Link>
                            <br />
                            <Link href=''>Terms & Conditions</Link>
                        </LinksWrapper>
                    </div>
                </FooterInner>
                <Box textAlign='center'>
                    ©{' '}
                    <a href='https://dnai.ai/' target='_blank' rel='noreferrer'>
                        DNAI
                    </a>{' '}
                    {new Date().getFullYear()}
                </Box>
            </Container>
        </FooterWrapper>
    )
}
