import { createAction } from '@reduxjs/toolkit'
import { PageType } from '@/store/reducers'

export const REORDER_ITEM = createAction(
    'REORDER_ITEM',
    function (pageType: PageType, from: number, to: number) {
        return {
            payload: {
                pageType,
                from,
                to,
            },
        }
    }
)

export const TOGGLE_NAVIGATION = createAction('TOGGLE_NAVIGATION')

export const SET_NAVIGATION = createAction(
    'SET_NAVIGATION',
    function (value: boolean) {
        return {
            payload: {
                value,
            },
        }
    }
)

export const TOGGLE_ITEM_ACTIVE = createAction(
    'SET_ITEM_ACTIVE',
    function (itemId: string, pageType: PageType) {
        return {
            payload: {
                itemId,
                pageType,
            },
        }
    }
)

export const ADD_RECENT_SEARCH_ITEM = createAction(
    'ADD_RECENT_SEARCH_ITEM',
    function (item: { label: string; url: string }) {
        return {
            payload: {
                item,
            },
        }
    }
)

export const TOGGLE_SEARCH_ERROR = createAction('TOGGLE_SEARCH_ERROR')

export const TOGGLE_ALL_ITEMS_VISIBLE = createAction(
    'TOGGLE_ALL_ITEMS_VISIBLE',
    function (pageType: PageType) {
        return {
            payload: {
                pageType,
            },
        }
    }
)

export const TOGGLE_ALL_ITEMS_INVISIBLE = createAction(
    'TOGGLE_ALL_ITEMS_INVISIBLE',
    function (pageType: PageType) {
        return {
            payload: {
                pageType,
            },
        }
    }
)

export const SET_SCROLL_TO_ELEMENT = createAction(
    'SET_SCROLL_TO_ELEMENT',
    function (elementIndex: number | null) {
        return {
            payload: {
                elementIndex,
            },
        }
    }
)
