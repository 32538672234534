// reference: https://github.com/broadinstitute/gnomad-browser-toolkit/blob/main/packages/track-variants/src/VariantPlot.js
import { scaleLog } from 'd3-scale'
import React, { useRef } from 'react'
import Canvas from '@/components/detail/DetailTrack/VariantTrack/Canvas'
import { Variant } from '@/generated/graphql'

const alleleFrequencyScale = scaleLog().domain([0.00001, 0.001]).range([4, 12])

const drawEllipse = (
    ctx: CanvasRenderingContext2D,
    cx: number,
    cy: number,
    rx: number,
    ry: number
) => {
    const K = 0.5522848

    const xOffset = rx * K
    const yOffset = ry * K

    const x1 = cx - rx
    const y1 = cy - ry

    const x2 = cx + rx
    const y2 = cy + ry

    ctx.moveTo(x1, cy)
    ctx.bezierCurveTo(x1, cy - yOffset, cx - xOffset, y1, cx, y1)
    ctx.bezierCurveTo(cx + xOffset, y1, x2, cy - yOffset, x2, cy)
    ctx.bezierCurveTo(x2, cy + yOffset, cx + xOffset, y2, cx, y2)
    ctx.bezierCurveTo(cx - xOffset, y2, x1, cy + yOffset, x1, cy)
}

type Props = {
    height?: number
    scalePosition: any
    variants: Variant[]
    variantColor: any
    width: number
}

export default function VariantPlot({
    height = 60,
    scalePosition,
    variants,
    variantColor,
    width,
}: Props) {
    const canvas = useRef<HTMLCanvasElement>(null)

    const variantsWithX = variants.map(variant => ({
        variant,
        x: scalePosition(variant.pos),
    }))

    return (
        <Canvas ref={canvas} width={width} height={height}>
            {ctx => {
                const markerY = height / 2

                ctx.clearRect(0, 0, width, height)
                ctx.lineWidth = 0.5
                ctx.strokeStyle = '#000'

                variantsWithX.forEach(({ variant, x }) => {
                    const fill = variantColor(variant)
                    const variantSource = variant.source as string[]
                    const variantAlleleFrequency = variantSource.includes(
                        'genome'
                    )
                        ? 1
                        : 1
                    const alleleFrequencyScaled = alleleFrequencyScale(
                        variantAlleleFrequency ? variantAlleleFrequency : 1
                    )

                    const rx = variantAlleleFrequency ? 3 : 1
                    const ry = alleleFrequencyScaled ? alleleFrequencyScaled : 1

                    ctx.beginPath()
                    drawEllipse(ctx, x, markerY, rx, ry)
                    ctx.closePath()
                    ctx.fillStyle = fill
                    ctx.fill()
                    ctx.lineWidth = 0.5
                    ctx.setLineDash([])
                    ctx.stroke()
                })
            }}
        </Canvas>
    )
}
