import * as React from 'react'

export default function CheckBoxOutlineBlankIcon() {
    return (
        <svg viewBox='0 0 24 24' width='14px' height='14px'>
            <path
                d='M4.8 4.8h14.4v14.4H4.8z'
                fill='none'
                stroke='currentColor'
                strokeLinecap='round'
                strokeWidth={1.6}
            />
        </svg>
    )
}
