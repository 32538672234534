import React from 'react'
import styled from 'styled-components'
import Typography from '@mui/material/Typography'

export const DetailSubheadingItemWrapper = styled.div`
  display: flex;
  margin-right: 15px;
  font-size: 10px;
`

const DetailSubheadingItemTypography = styled(Typography)`
  font-weight: bold;
  font-size: 10px;
  margin-right: 12px;
  white-space: nowrap;
`

type Props = {
    title: string
    children: React.ReactNode
    condition: any
}

export default function DetailSubheadingItem({
    title,
    children,
    condition,
}: Props) {
    return (
        <DetailSubheadingItemWrapper>
            <DetailSubheadingItemTypography variant='body1' color='black.main'>
                {title}
            </DetailSubheadingItemTypography>
            {condition ? children : <>n/a</>}
        </DetailSubheadingItemWrapper>
    )
}
